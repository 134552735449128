import { RouteProps } from 'react-router-dom'

export const RBAC = {
    mandant: 'rbac_mandant',
    anwalt: 'rbac_anwalt',
}

export type KcRoleSecuredRouteProps = RouteProps & {
    path: string
    fallbackOnUnauthorized?: string
    element: React.ReactElement
}

export const PortRoutes = {
    common: {
        home: '/',
    },
    admin: {
        dashboard: '/dashboard',
        anfragen: '/anfragen',
        anfrage_by_id: '/anfragen/:id/:caseid',
        mandanten: '/mandanten',
        mandanten_by_id: '/mandanten/:id/:caseid?',
    },
    MANDANT: {
        faelle: '/faelle',
        faelle_by_id: '/faelle/:id',
        profil: '/profil',
    },
}
