import { HashLink } from "react-router-hash-link";
import { ReactComponent as ThumbsUp } from "./../../../assets/ThumbsUp.svg";
import { motion } from "framer-motion";
import { ScreenType } from "../Types";

export function CloseScreen(props: ScreenType) {
  return (
    <motion.div
      initial={{ x: (props.screen < 4 && props.screen !== 5) ? "120%" : 0 }}
      animate={{
        x: props.screen === 4 ? 0 : props.screen > 3 ? "-120%" : undefined,
      }}
      transition={{
        type: "ease",
      }}
      className="bg-base-200 h-full w-full absolute flex flex-col p-4 rounded-default items-center justify-center"
    >
      <motion.div
        initial={{
          rotate: props.screen === 3 ? 0 : 0,
          scale: props.screen === 3 ? 0 : 0,
        }}
        animate={{
          rotate: props.screen === 3 ? 360 : undefined,
          scale: props.screen === 3 ? 3 : 1,
        }}
        transition={{
          delay: 1,
          loop: Infinity,
          transition: "ease",
        }}
      >
        <ThumbsUp />
      </motion.div>
      <p className="text-xl font-semibold pt-8">
        Geschafft! Danke für Ihr Vertrauen!
      </p>
      <p className="text-xl font-semibold">
        Wir werden uns zeitnah bei Ihnen melden.
      </p>
      <HashLink to="/" className="uppercase pt-2 underline">
        Hier gehts zum Portal
      </HashLink>
    </motion.div>
  );
}
