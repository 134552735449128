import React, { useContext, useEffect, useState } from "react";
import BasePanelChat from "../../components/base/BasePanelChat";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import CaseList from "../../components/customer/CaseList";
import { useParams } from "react-router-dom";
import { caseType } from "../../types/Types";
import AddCaseView from "../../components/customer/AddCaseView";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getOneUser } from "../../api/apiCalls";
import ErrorPage from "../common/ErrorPage";
import Loading from "../../components/common/Loading";
import BaseCaseInterface from "../../components/base/BaseCaseInterface";
import { ModalContext } from "../../components/context/ModalContext/ModalContext";
import AddDocModal from "../../components/context/ModalContext/AddDocModal";
import BaseActionCheckModal from "../../components/base/BaseActionCheckModal";
import { useRequestToDeleteMutation } from "../../api/services";

export default function UserScreen1() {
  const { id } = useParams(); // NOTE noch benötigt?

  const { data, isLoading, error } = useQuery({
    queryKey: ["oneUser"],
    queryFn: getOneUser,
  });

  const [selectedCase, setselectedCase] = useState<caseType | undefined>(
    data?.cases[0]
  );
  useEffect(() => {
    if (data) {
      setselectedCase(
        data?.cases?.find((obj: caseType) => obj._id === id) ?? data.cases[0]
      );
    }
  }, [data, id]);

  const [addCaseView, setaddCaseView] = useState<boolean>(false);
  const modalCon = useContext(ModalContext);

  // User - request to delete case
  const queryClient = useQueryClient();
  const { mutate: reqToDeleteCase } = useRequestToDeleteMutation(queryClient);
  const handleDeleteCaseReq = () => {
    reqToDeleteCase({
      topic: "case",
      _id: data._id,
      personal: {
        first_name: data.personal?.first_name ?? "",
        last_name: data.personal?.last_name ?? "",
        caseid: selectedCase?._id ?? "",
      },
    });
    modalCon?.trigger(5);
  };

  return (
    <>
      {error && <ErrorPage />}
      {isLoading && <Loading />}
      {!error && !isLoading && data && (
        <BasePanelLayout>
          <CaseList
            cases={data.cases}
            selectedId={Number(id)}
            addCase={setaddCaseView}
          />
          {addCaseView ? (
            <AddCaseView setaddCaseView={() => setaddCaseView(false)} />
          ) : (
            <>
              <BaseCaseInterface
                selectedCase={selectedCase}
                ustatus={data.status}
                deleteCaseReq={() => modalCon?.trigger(5)}
                openUploadModal={() => modalCon?.trigger(2)}
              />
              <BasePanelChat
                status={selectedCase?.status !== "open"}
                sender="rbac_mandant"
                chat={selectedCase?.chat ?? []}
                cid={selectedCase?._id ?? ""}
                uid={data?._id}
              />
            </>
          )}
          <AddDocModal
            mid={2}
            uid={data?._id ?? ""}
            cid={selectedCase?._id ?? ""}
            modalCon={modalCon}
          />
          <BaseActionCheckModal
            modalId={5}
            title={"Löschung beantragen"}
            buttonTitle={"Beantragen"}
            text={
              "Sind Sie sicher, dass Sie den Fall löschen lassen wollen? Es können bereits entstandene Kosten anfallen."
            }
            function={handleDeleteCaseReq}
          />
        </BasePanelLayout>
      )}
    </>
  );
}
