import '../../style/loading.css'

type LoadingUploadType = {
    hfull?: boolean
}

export default function LoadingUpload(props: LoadingUploadType) {
    return (
        <div
            className={`${
                props.hfull ? 'h-[80%]' : 'h-screen top-24'
            } w-full absolute left-0 bg-base-100/70 flex flex-col items-center justify-center`}>
            <div className="dot-spinner">
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
                <div className="dot-spinner__dot"></div>
            </div>
            <p className="text-primary uppercase font-semibold underline pt-2">
                Fenster nicht schließen, solange der Upload läuft
            </p>
        </div>
    )
}
