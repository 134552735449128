type CaseElementType = {
  text: string;
  selected: boolean;
  func: () => void;
};

export function CaseElement(props: CaseElementType) {
  return (
    <div
      onClick={props.func}
      className={`w-fit h-12 px-4 ${
        props.selected
          ? "border-primary border-2 bg-base-300 font-bold tracking-wide"
          : "border border-neutral"
      } flex justify-center rounded-default items-center cursor-pointer`}
    >
      <p className="min-w-[12rem] max-w-fit text-center">{props.text}</p>
    </div>
  );
}
