import { Navigate, Route, Routes } from "react-router-dom";
import Portal from "./pages/common/Portal";
import "./style/scrollbar.css";
import { useContext } from "react";
import { LightDarkModeContext } from "./components/context/LightDarkModeContext/LightDarkModeContext";
import Registration from "./pages/registration/Registration";

function App() {
  const THEME = {
    DEFAULT: "standard",
    DARK: "darktheme",
    CUSTOM: "custom",
  };

  const modeCon = useContext(LightDarkModeContext);

  return (
    <section
      className="grow w-full flex flex-col justify-center items-center relative bg-base-100 text-neutral font-default"
      data-theme={modeCon?.lightmode ? THEME.DEFAULT : THEME.DARK}
    >
      {/* <BasePortalHeader /> */}
      <div className="h-screen w-full max-w-screen-3xl relative hidden lg:flex gap-default p-4 overflow-hidden">
        <Routes>
          <Route path="/" element={<Navigate to="/registrierung" />} />
          <Route path="/portal/*" element={<Portal />} />
          <Route path="/registrierung/*" element={<Registration />} />
          {/* <Route path="/registrierung/:law" element={<Registration />} /> */}
        </Routes>
      </div>
    </section>
  );
}

export default App;
