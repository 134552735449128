import "../../style/loading.css";

type LoadingType = {
  bg?: string;
};

export default function Loading(props: LoadingType) {
  return (
    <section
      className={`h-screen w-full overflow-hidden flex items-center justify-center ${
        props.bg ? props.bg : "bg-base-100"
      }`}
    >
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </section>
  );
}
