import { ReactNode } from "react";

type BaseButtonType = {
  text: string;
  buttonMood?: string;
  icon: ReactNode;
  iconLast?: boolean;
  onClick?: () => void // TODO required
};

export default function BaseButton(props: BaseButtonType) {
  return (
    <div
      className={`btn gap-2 ${
        props.buttonMood ? props.buttonMood : "btn-primary"
      }`}
      onClick={props.onClick}
    >
      {!props.iconLast && props.icon}
      <p>{props.text}</p>
      {props.iconLast && props.icon}
    </div>
  );
}
