import { useContext, useEffect } from "react";
import BaseToggle from "../../../../../components/base/BaseToggle";
import BaseViolationForm from "../../../../../components/base/BaseViolationForm";
import { AddUserContext } from "../../../../../components/context/AddUserContext/AddUserContext";
import BaseDocsSelection from "../../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../../Types";
import BaseInputField from "../../../../../components/base/BaseInputField";
import BaseTextAreaSpecialties from "../../../../../components/base/BaseTextAreaSpecialties";
import { checkForValue } from "../../../../../services/functions";

export default function AlcoholViolation(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);
  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", "Alkoholverstoß");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      checkForValue(addCaseContext?.caseData?.toggle1) &&
      checkForValue(addCaseContext?.caseData?.toggle2) &&
      checkForValue(addCaseContext?.caseData?.toggle3) &&
      checkForValue(addCaseContext?.caseData?.toggle4) &&
      checkForValue(addCaseContext?.caseData?.dd1)
    ) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <BaseToggle
        checked={addCaseContext?.caseData?.toggle1}
        toggle={"toggle1"}
        value1="Atemalkohol"
        value2="Blutalkohol"
        group="messearedvaluesssssssss"
        title={"Gemessener Wert *"}
        label1={"Atemalkohol"}
        label2={"Blutalkohol"}
      />
      <p className="pt-8">Wertangabe *</p>
      <select
        value={
          addCaseContext?.caseData?.dd1 === ""
            ? undefined
            : addCaseContext?.caseData?.dd1
        }
        onChange={(e) => addCaseContext?.changeData(2, "dd1", e.target.value)}
        className="select select-bordered w-full bg-base-200 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Wert 0,25 - 0,4"}>0,25 - 0,4</option>
        <option value={"Wert 0,4 - 0,55"}>0,4 - 0,55</option>
        <option value={"Wert mehr als 0,55"}>mehr als 0,55</option>
      </select>
      <BaseToggle
        value1={"Unfall: Ja"}
        value2={"Unfall: Nein"}
        checked={addCaseContext?.caseData?.toggle2}
        toggle={"toggle2"}
        group="crashssssssss"
        title="Kam es zu einem Unfall? *"
        label1="Ja"
        label2="Nein"
      />
      <BaseToggle
        checked={addCaseContext?.caseData?.toggle3}
        toggle={"toggle3"}
        value1="Führerschein beschlagnahmt?: Ja"
        value2="Führerschein beschlagnahmt?: Nein"
        group="catchdriverslicensessssssss"
        title={"Wurde der Führerschein beschlagnahmt? *"}
        label1={"Ja"}
        label2={"Nein"}
      />
      <BaseToggle
        checked={addCaseContext?.caseData?.toggle4}
        toggle={"toggle4"}
        value1="vorläufige Entziehung der Fahrerlaubnis?: Ja"
        value2="vorläufige Entziehung der Fahrerlaubnis?: Nein"
        group="maystilldrivesssssssss"
        title={"Beschluss über vorläufige Entziehung der Fahrerlaubnis? *"}
        label1={"Ja"}
        label2={"Nein"}
      />
      <BaseTextAreaSpecialties
        title={"Bemerkung "}
        heightProps="max-h-32 h-full"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={"Frist des Falls"}
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection extend={false} />
    </BaseViolationForm>
  );
}
