import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { createNewEvent, getAllEventsInTheNextWeek } from './apiCall'
import { MSCalendarCreateEventType } from '../../types/MSTypes'
import { userType } from '../../types/Types'

const QK = {
    msEvents: 'msEvents',
}

export const useCreateMSEventMutation = (
    queryClient: QueryClient,
    onSuccessExtend?: (data: unknown) => void,
    onErrorExtend?: (error: Error) => void
) => {
    return useMutation<
        unknown,
        Error,
        {
            tokenString?: string
            customer: userType
            newEvent: MSCalendarCreateEventType
        }
    >({
        mutationFn: createNewEvent,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [QK.msEvents] })
            if (onSuccessExtend) onSuccessExtend(data)
        },

        onError: (err) => {
            console.log(err)
            if (onErrorExtend) onErrorExtend(err)
        },
    })
}

export const useGetMSEventsQuery = (tokenString?: string) => {
    return useQuery({
        queryKey: [QK.msEvents, tokenString],
        queryFn: () => getAllEventsInTheNextWeek(tokenString),
    })
}
