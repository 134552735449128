import { useContext, useEffect } from "react";
import BaseTextAreaSpecialties from "../../../../../components/base/BaseTextAreaSpecialties";
import BaseViolationForm from "../../../../../components/base/BaseViolationForm";
import { AddUserContext } from "../../../../../components/context/AddUserContext/AddUserContext";
import BaseDocsSelection from "../../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../../Types";
import BaseInputField from "../../../../../components/base/BaseInputField";
import { checkForValue } from "../../../../../services/functions";

export default function ParkingViolation(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);
  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", "Park- & Abschleppmaßnahmen");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (checkForValue(addCaseContext?.caseData?.note)) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <BaseTextAreaSpecialties
        title={"Kurze Erläuterung *"}
        heightProps="h-80"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={"Frist des Falls"}
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection extend={false} />
    </BaseViolationForm>
  );
}
