import BaseCaseForm from "../../../base/BaseCaseForm";
import ParkingViolationScreen1 from "./ParkingViolationScreen1";

export type ParkingViolationType = {
  check1: boolean;
  setcheck1: (e: boolean) => void;
  check2: boolean;
  setcheck2: (e: boolean) => void;
  note: string;
  setnote: (e: string) => void;
  extend?: boolean;
  handleSubmit?: () => void;
  date: string;
  setdate: (e: string) => void;
  setdoc1: (e: string) => void;
  setdoc2: (e: string) => void;
  setdoc3: (e: string) => void;
  documents: any;
  setdocuments: (e: any) => void;
};

export default function ParkingViolation({
  note,
  check1,
  check2,
  date,
  documents,
  setdocuments,
  setcheck1,
  setcheck2,
  setnote,
  setdate,
  handleSubmit,
  setdoc1,
  setdoc2,
  setdoc3,
}: ParkingViolationType) {
  return (
    <BaseCaseForm
      buttonRow={
        <div className="pl-12 w-full">
          <div className="flex gap-2 w-full">
            <button
              onClick={handleSubmit}
              className="btn bg-accent grow text-base-100 hover:bg-accent/90"
              disabled={!check1 || !check2 || !note || note.length < 10}
            >
              Fall melden
            </button>
          </div>
        </div>
      }
    >
      <ParkingViolationScreen1
        documents={documents}
        setdocuments={setdocuments || (() => {})}
        check1={check1}
        check2={check2}
        setcheck1={setcheck1}
        setcheck2={setcheck2}
        note={note}
        setnote={setnote}
        date={date}
        setdate={setdate}
        setdoc1={setdoc1}
        setdoc2={setdoc2}
        setdoc3={setdoc3}
      />
    </BaseCaseForm>
  );
}
