import BasePanelHeadline from "../../components/base/BasePanelHeadline";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import { useContext, useEffect, useState } from "react";
import { adressType, personalType } from "../../types/Types";
import BaseInputObjectField from "../../components/base/BaseInputObjectField";
import {
  useRequestToDeleteMutation,
  useUpdatePersonalDataMutation,
} from "../../api/services";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getOneUser } from "../../api/apiCalls";
import ErrorPage from "..//common/ErrorPage";
import Loading from "../../components/common/Loading";
import dayjs from "dayjs";
import BaseActionCheckModal from "../../components/base/BaseActionCheckModal";
import { ModalContext } from "../../components/context/ModalContext/ModalContext";

export default function UserProfile() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["oneUser"],
    queryFn: getOneUser,
  });

  const [userData, setUserData] = useState<personalType>({
    first_name: "",
    last_name: "",
    mail: "",
    tel: "",
    insurance: false,
    adress: {
      street: "",
      nr: "",
      plz: "",
      city: "",
    },
    callOption: 0,
  });

  const queryClient = useQueryClient();
  const { mutate: updateUser } = useUpdatePersonalDataMutation(queryClient);
  const handleUpdateSend = () => {
    updateUser({
      uid: data._id,
      personal: { personal: userData },
    });
  };

  useEffect(() => {
    if (data) {
      setUserData(data?.personal);
    }
  }, [data]);

  const updateProfile = (
    key: keyof personalType | keyof adressType,
    value: string | number,
    nestedKey?: keyof personalType
  ) => {
    setUserData((prevData) => {
      if (nestedKey && nestedKey in prevData) {
        return {
          ...prevData,
          [nestedKey]: {
            ...(prevData[nestedKey] as any),
            [key]: value,
          },
        };
      }
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  return (
    <BasePanelLayout>
      {error && <ErrorPage />}
      {isLoading && <Loading />}
      {userData && !error && !isLoading && data && (
        <div className="w-full h-full bg-portal-gray flex flex-col p-8 items-center bg-base-300 rounded-default">
          <BasePanelHeadline title={"Profil"} />
          <p className="text-sm text-primary font-semibold text-right w-full max-w-screen-lg">
            letztes Update:{" "}
            <span>
              {dayjs(data?.updated_at).format("DD.MM.YYYY HH:mm")} Uhr{" "}
            </span>
          </p>
          {/* NAME */}
          <div className="flex flex-row gap-4 w-full max-w-screen-lg">
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Vorname"}
              type={"text"}
              placeholder={"Text"}
              value={userData?.first_name}
              setvalue={(e: any) => updateProfile("first_name", e.target.value)}
            />
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Nachname"}
              type={"text"}
              placeholder={"Text"}
              value={userData.last_name}
              setvalue={(e: any) => updateProfile("last_name", e.target.value)}
            />
          </div>
          {/* ADRESS */}
          <div className="flex flex-row gap-4 w-full max-w-screen-lg">
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Straße"}
              type={"text"}
              placeholder={"Nachname"}
              value={userData.adress.street}
              setvalue={(e: any) =>
                updateProfile("street", e.target.value, "adress")
              }
            />
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Nummer"}
              type={"text"}
              placeholder={"Nr. 12"}
              value={userData.adress.nr}
              setvalue={(e: any) =>
                updateProfile("nr", Number(e.target.value), "adress")
              }
            />
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Postleitzahl"}
              type={"text"}
              placeholder={"01234"}
              value={userData.adress.plz}
              setvalue={(e: any) =>
                updateProfile("plz", Number(e.target.value), "adress")
              }
            />
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Stadt"}
              type={"text"}
              placeholder={"Stadt"}
              value={userData.adress.city}
              setvalue={(e: any) =>
                updateProfile("city", e.target.value, "adress")
              }
            />
          </div>
          {/* CONTACT */}
          <div className="flex flex-row gap-4 w-full max-w-screen-lg">
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"Telefon"}
              type={"text"}
              placeholder={"0123456789"}
              value={userData.tel}
              setvalue={(e: any) => updateProfile("tel", e.target.value)}
            />
            <BaseInputObjectField
              classProps="bg-portal-gray"
              label={"E-Mail"}
              type={"text"}
              placeholder={"max@mustermann.de"}
              value={userData.mail}
              setvalue={(e: any) => updateProfile("mail", e.target.value)}
            />
          </div>
          {/* CALL */}
          <div className="flex flex-row justify-evenly w-full max-w-screen-lg py-8">
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                name={"callOption"}
                className="radio radio-primary border-2"
                checked={userData.callOption === 1}
                onChange={() => updateProfile("callOption", 1)}
              />
              <label>Morgens (8-11 Uhr)</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                name={"callOption"}
                className="radio radio-primary border-2"
                checked={userData.callOption === 2}
                onChange={() => updateProfile("callOption", 2)}
              />
              <label>Mittag (11-14 Uhr)</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                name={"callOption"}
                className="radio radio-primary border-2"
                checked={userData.callOption === 3}
                onChange={() => updateProfile("callOption", 3)}
              />
              <label>Nachmittag (14-17 Uhr)</label>
            </div>
            <div className="flex gap-3 items-center">
              <input
                type="radio"
                name={"callOption"}
                className="radio radio-primary border-2"
                checked={userData.callOption === 4}
                onChange={() => updateProfile("callOption", 4)}
              />
              <label>Abends (17-20 Uhr)</label>
            </div>
          </div>

          <div className="w-full max-w-screen-lg flex justify-between">
            {userData !== data?.personal && (
              <button className="btn btn-success" onClick={handleUpdateSend}>
                Speichern
              </button>
            )}
          </div>
        </div>
      )}
    </BasePanelLayout>
  );
}
