import React from "react";
import { Navigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { RBAC } from "./shared";

type UseAnwaltSecuredProps = {
  target: React.ReactElement;
};

export const useAnwaltSecuredElement = ({ target }: UseAnwaltSecuredProps) => {
  const { keycloak, initialized } = useKeycloak();
  if (!keycloak.authenticated) keycloak.login();

  const isAuthorized = () =>
    initialized && keycloak.authenticated && keycloak.hasRealmRole(RBAC.anwalt); // Make sure 'anwalt' matches the role name exactly
  return isAuthorized() ? (
    target
  ) : (
    <Navigate to={PortalRoutes.common.portal_entry} />
  );
};

export const useMandantenSecuredElement = ({
  target,
}: UseAnwaltSecuredProps) => {
  const { keycloak, initialized } = useKeycloak();
  if (!keycloak.authenticated) keycloak.login();

  const isAuthorized = () =>
    initialized &&
    keycloak.authenticated &&
    keycloak.hasRealmRole(RBAC.mandant); // Make sure 'anwalt' matches the role name exactly

  return isAuthorized() ? (
    target
  ) : (
    <Navigate to={PortalRoutes.common.portal_entry} />
  );
};

export const NavigateBasedOnRole = ({
  fallbackOnUnauthorized = "/",
}: {
  fallbackOnUnauthorized?: string;
}) => {
  const { keycloak, initialized } = useKeycloak();
  if (!keycloak.authenticated) {
    keycloak.login();
  }

  const isAnwalt = () =>
    initialized && keycloak.authenticated && keycloak.hasRealmRole(RBAC.anwalt); // Make sure 'anwalt' matches the role name exactly
  const isMandant = () =>
    initialized &&
    keycloak.authenticated &&
    keycloak.hasRealmRole(RBAC.mandant); // Make sure 'anwalt' matches the role name exactly

  const route = isAnwalt()
    ? relativeRoute(PortalRoutes.anwalt.dashboard)
    : isMandant()
    ? relativeRoute(PortalRoutes.mandant.faelle)
    : fallbackOnUnauthorized;

  return <Navigate to={route} />;
};

export const PortalRoutes = {
  common: {
    home: "/",
    portal_entry: "/portal",
    hilfe: "/hilfe",
    profil: "/profil",
    settings: "/einstellungen",
  },
  anwalt: {
    dashboard: "/dashboard",
    anfragen: "/anfragen",
    anfrage_by_id: "/anfragen/:id/:caseid",
    mandanten: "/mandanten",
    mandanten_by_id: "/mandanten/:id/:caseid?",
    cases: "/anwalt/faelle",
    cases_by_id: "/anwalt/faelle/:id",
  },
  mandant: {
    faelle: "/mandant/faelle",
    faelle_by_id: "/mandant/faelle/:id",
  },
};

export const relativeRoute = (route: string): string =>
  route.startsWith("/") ? route.slice(1) : route;
