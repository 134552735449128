import { useContext } from "react";
import { caseType } from "../../types/Types";
import dayjs from "dayjs";
import { ModalContext } from "../context/ModalContext/ModalContext";
import PlanMeetingModal from "../admin/PlanMeetingModal";
import { getDocToDownload } from "../../api/apiCalls";
import { ReactComponent as CalendarICON } from "../../assets/Calendar.svg";
import { ReactComponent as ApproveICON } from "../../assets/Approve.svg";
import { ReactComponent as CloseICON } from "../../assets/Close.svg";
import { ReactComponent as DoneICON } from "../../assets/Done.svg";
import { ReactComponent as TrashICON } from "../../assets/Trash.svg";
import { ReactComponent as UploadICON } from "../../assets/Upload.svg";
import { ReactComponent as DocumentICON } from "../../assets/Document.svg";
import { ReactComponent as DownloadICON } from "../../assets/Download.svg";
import BaseActionButton from "./BaseActionButton";

type CaseInterfaceType = {
  ustatus: string;
  selectedCase?: caseType;
  adminView?: boolean;
  openUploadModal: () => void;
  deleteNewCase?: () => void;
  deleteExistingCase?: () => void;
  acceptCase?: () => void;
  closeCase?: () => void;
  deleteCaseReq?: () => void;
};

export default function CaseInterface({
  ustatus,
  selectedCase,
  adminView,
  openUploadModal,
  deleteNewCase,
  deleteExistingCase,
  acceptCase,
  closeCase,
  deleteCaseReq,
}: CaseInterfaceType) {
  // download file

  const handleDownload = (id: string, displayFileName: string) => {
    getDocToDownload(id, displayFileName).catch((err) => {
      alert("Fehler beim Download - Bitte versuchen Sie es später erneut...");
      console.log(err);
    });
  };

  // Modal Context
  const modalCon = useContext(ModalContext);

  return (
    <div className="grow flex flex-col p-16 rounded-default bg-base-300 gap-4 relative">
      <div className="flex flex-col leading-5">
        <p className="text-lg font-semibold">
          Fall {dayjs(selectedCase?.created_at).format("DD.MM.YYYY")}
        </p>
        <p className="text-sm">
          Letztes Update:{" "}
          {dayjs(selectedCase?.updated_at).format("DD.MM.YYYY HH:mm")} Uhr
        </p>
        <p className={adminView ? "text-lg font-semibold pt-4" : "text-sm"}>
          Frist:{" "}
          {dayjs(selectedCase?.deadline).isValid()
            ? dayjs(selectedCase?.deadline).format("DD.MM.YYYY")
            : "keine Angabe"}
        </p>
        <p className={adminView ? "" : "text-lg font-medium pt-8"}>
          {selectedCase?.title}
        </p>
        {/* TODO callOption kommt von persönlichen Infos -> als einzelne Var übergeben */}
        {/* <p className='text-lg font-medium pt-4 pb-4'>Rückruf: {selectedCase?.callOption}</p> */}
        {selectedCase?.info?.map((item, index) => (
          <p key={index} className={`${index === 0 && "pt-4"}`}>
            {item}
          </p>
        ))}
        {selectedCase?.note && (
          <p className="pt-4">Bemerkung: {selectedCase?.note}</p>
        )}
        {!selectedCase?.documents || selectedCase.documents.length === 0 ? (
          <p className="pt-4">Keine Dokumente hochgeladen</p>
        ) : (
          selectedCase?.documents?.map((item, index) => (
            <div
              key={index}
              className={`flex items-center justify-between ${
                index === 0 ? "pt-4" : "pt-1"
              }`}
            >
              <div className="flex items-center">
                <DocumentICON className="text-primary mr-2 w-6 h-6" />

                <p>{item.title}</p>
              </div>
              <DownloadICON
                className="text-primary cursor-pointer w-6 h-6"
                onClick={() => handleDownload(item.filename, item.title)}
              />
            </div>
          ))
        )}
      </div>
      <div className="grow" />
      <div className="flex flex-row justify-end gap-2 w-full">
        {selectedCase?.status !== "done" && (
          <>
            {selectedCase?.status === "new" &&
            adminView &&
            ustatus === "taken" ? (
              <>
                <BaseActionButton
                  svg={<ApproveICON />}
                  color={"btn-success"}
                  explanation={"Fall annehmen"}
                  function={acceptCase ?? (() => {})}
                />
                <BaseActionButton
                  svg={<CalendarICON />}
                  color={"btn-primary btn-outline border"}
                  explanation={"Telefonat planen"}
                  function={() => modalCon?.trigger(11)}
                />
              </>
            ) : selectedCase?.status === "open" ? (
              <BaseActionButton
                svg={<UploadICON />}
                color={"btn-primary"}
                explanation={"Dokumentupload"}
                function={openUploadModal}
              />
            ) : (
              <></>
            )}
            {selectedCase?.status === "open" &&
              adminView &&
              ustatus !== "waitlist" && (
                <BaseActionButton
                  svg={<DoneICON />}
                  color={"btn-success"}
                  explanation={"Fall schließen"}
                  function={closeCase ?? (() => {})}
                />
              )}
            {selectedCase?.status !== "open" && adminView && (
              <BaseActionButton
                svg={<CloseICON />}
                color={"btn-error"}
                explanation={"Fall ablehnen"}
                function={deleteNewCase ?? (() => {})}
              />
            )}
          </>
        )}
        {selectedCase?.status !== "new" && adminView && (
          <BaseActionButton
            svg={<TrashICON />}
            color={"btn-accent btn-outline border"}
            explanation={"Fall löschen"}
            function={deleteExistingCase ?? (() => {})}
          />
        )}
        {!adminView && (
          <BaseActionButton
            svg={<TrashICON />}
            color={"btn-error btn-outline border"}
            explanation={"Löschung beantragen"}
            function={deleteCaseReq ?? (() => {})}
          />
        )}
      </div>
      <PlanMeetingModal modalId={11} />
    </div>
  );
}
