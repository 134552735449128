import { motion } from "framer-motion";
import { ScreenType } from "../../Types";
import BasePanelHeadline from "../../../../components/base/BasePanelHeadline";
import { useEffect, useState } from "react";
import PaceViolation from "./violations/PaceViolation";
import RedlightViolation from "./violations/RedlightViolation";
import SmartphoneViolation from "./violations/SmartphoneViolation";
import AlcoholViolation from "./violations/AlcoholViolation";
import RaceViolation from "./violations/RaceViolation";
import ParkingViolation from "./violations/ParkingViolation";
import DifferentViolation from "./violations/DifferentViolation";
import DistanceViolation from "./violations/DistanceViolation";

const traficViolations = [
  "Geschwindigkeit",
  "Rotlichtverstoß",
  "Alkoholverstoß",
  "Handyverstoß",
  "Abstandsverstoß",
  "Illegale Autorennen",
  "Parken & Abschleppen",
  "Anderer Verstoß",
];

export function TraficLawMainScreen(props: ScreenType) {
  const [violation, setviolation] = useState("Geschwindigkeit");
  useEffect(() => {
    props.setfilledOut(false);
  }, [violation]);

  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: props.screen === 1 ? 0 : "-120%" }}
      transition={{
        type: "ease",
      }}
      className="bg-base-200 h-full w-full flex flex-col p-4 rounded-default absolute"
    >
      <BasePanelHeadline title={"Wähle einen Verstoß"} />
      <div className="w-full overflow-scroll overflow-y-hidden h-20 flex gap-2 pt-4 pb-2">
        {traficViolations.map((item, index) => (
          <ViolationElement
            text={item}
            key={index}
            selected={item === violation}
            func={() => setviolation(item)}
          />
        ))}
      </div>
      {violation === "Geschwindigkeit" && (
        <PaceViolation setfilledOut={() => props?.setfilledOut(true)} />
      )}
      {violation === "Rotlichtverstoß" && (
        <RedlightViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Handyverstoß" && (
        <SmartphoneViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Abstandsverstoß" && (
        <DistanceViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Alkoholverstoß" && (
        <AlcoholViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Illegale Autorennen" && (
        <RaceViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Parken & Abschleppen" && (
        <ParkingViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
      {violation === "Anderer Verstoß" && (
        <DifferentViolation setfilledOut={() => props.setfilledOut(true)} />
      )}
    </motion.div>
  );
}

type ViolationElementType = {
  text: string;
  selected: boolean;
  func: () => void;
};

function ViolationElement(props: ViolationElementType) {
  return (
    <div
      onClick={props.func}
      className={`w-fit h-12 px-4 ${
        props.selected
          ? "border-primary border-2 bg-base-300 font-bold tracking-wide"
          : "border border-neutral"
      } flex justify-center rounded-default items-center cursor-pointer`}
    >
      <p className="min-w-[12rem] max-w-fit text-center">{props.text}</p>
    </div>
  );
}
