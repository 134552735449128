import axios from "axios";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const updateKcToken = (token: string) => {
  console.log(token);
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
