import React, { ReactNode, createContext, useState } from "react";
export type LightDarkModeContextProps = {
    lightmode: boolean,
    setlightmode: (e: boolean) => void
};

export type LightDarkModeContextProviderProps = {
  children: ReactNode;
};

export const LightDarkModeContext = createContext<
  LightDarkModeContextProps | undefined
>(undefined);

export const LightDarkModeContextProvider: React.FC<
  LightDarkModeContextProviderProps
> = ({ children }: LightDarkModeContextProviderProps) => {
  const [lightmode, setlightmode] = useState(true);

  return (
    <LightDarkModeContext.Provider
      value={{
        lightmode,
        setlightmode,
      }}
    >
      {children}
    </LightDarkModeContext.Provider>
  );
};
