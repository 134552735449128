import { motion } from "framer-motion";
import { ScreenType } from "../../Types";
import BasePanelHeadline from "../../../../components/base/BasePanelHeadline";
import { useEffect, useState } from "react";
import { CaseElement } from "../../components/BaseElement";
import BaseDataGatheringScreen from "./BaseDataGatheringScreen";

const roles = ["Arbeitnehmer", "Arbeitgeber"];

export function EmploymentLawMainScreen(props: ScreenType) {
  const [role, setrole] = useState("Arbeitnehmer");
  useEffect(() => {
    props.setfilledOut(false);
  }, [role]);

  return (
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: props.screen === 1 ? 0 : "-120%" }}
      transition={{
        type: "ease",
      }}
      className="bg-base-200 h-full w-full flex flex-col p-4 rounded-default absolute"
    >
      <BasePanelHeadline title={"Wer sind Sie?"} />
      <div className="w-full overflow-scroll overflow-y-hidden h-20 flex gap-2 pt-4 pb-2 justify-evenly">
        {roles.map((item, index) => (
          <CaseElement
            text={item}
            key={index}
            selected={item === role}
            func={() => setrole(item)}
          />
        ))}
      </div>
      <BaseDataGatheringScreen
        role={"Arbeitsrecht - " + role}
        setfilledOut={() => props?.setfilledOut(true)}
      />
    </motion.div>
  );
}
