import BaseViolationForm from "../../../base/BaseViolationForm";
import BaseToggle from "../../../base/BaseToggle";
import { SmartphoneViolationType } from "./SmartphoneViolation";

export default function DifferentViolationScreen1({
  vehicle,
  crash,
  necessary,
  setvehicle,
  setcrash,
  setnecessary,
}: SmartphoneViolationType) {
  return (
    <BaseViolationForm>
      <BaseToggle
        group="vehicletyp"
        title={"Fahrzeugtyp"}
        label1={"PKW/Motorrad"}
        label2={"LKW"}
        checked={vehicle}
        value1="Fahrzeugtyp: PKW/Motorrad"
        value2="Fahrzeugtyp: LKW"
        toggle="placeholder!!!"
        // onChange={setvehicle}
      />
      <BaseToggle
        group="crash"
        title={"Kam es zu einem Unfall?"}
        label1={"Ja"}
        label2={"Nein"}
        checked={crash}
        value1="Unfall: Ja"
        value2="Unfall: Nein"
        toggle="placeholder!!!"
        // onChange={setcrash}
      />
      <BaseToggle
        group="necessary"
        title={"Sind Sie beruflich auf den Führerschein angewiesen?"}
        label1={"Ja"}
        label2={"Nein"}
        checked={necessary}
        value1="Beruflich Angewiesen?: Ja"
        value2="Beruflich Angewiesen?: Nein"
        toggle="placeholder!!!"
        // onChange={setnecessary}
      />
    </BaseViolationForm>
  );
}
