import BaseDataCheck from "../../base/BaseDataCheck";
import BaseDocsSelection from "../../base/BaseDocsSelection";
import BaseTextAreaSpecialties from "../../base/BaseTextAreaSpecialties";
import BaseViolationForm from "../../base/BaseViolationForm";

type FormScreen2Type = {
  check1: boolean;
  setcheck1: (e: boolean) => void;
  check2: boolean;
  setcheck2: (e: boolean) => void;
  note: string;
  setnote: (e: string) => void;
  extend?: boolean;
  setdoc1: (e: string) => void;
  setdoc2: (e: string) => void;
  setdoc3: (e: string) => void;
  setdoc4?: (e: string) => void;
  setdoc5?: (e: string) => void;
  documents: any[];
  setdocuments: (e: any) => void;
};

export default function FormScreen2({
  note,
  setnote,
  check1,
  check2,
  documents,
  setdocuments,
  setcheck1,
  setcheck2,
  setdoc1,
  setdoc2,
  setdoc3,
  setdoc4,
  setdoc5,
  extend,
}: FormScreen2Type) {
  return (
    <BaseViolationForm>
      {!extend && (
        <BaseTextAreaSpecialties
          value={note}
          title={"Liegen Besonderheiten vor?"}
        />
      )}
      {/* <BaseDocsSelection
                documents={documents}
                setdocuments={setdocuments}
                extend={extend ?? false}
                setdate={function (e: any): void {
                    throw new Error('Function not implemented.')
                }}
                setdoc1={setdoc1}
                setdoc2={setdoc2}
                setdoc3={setdoc3}
                setdoc4={setdoc4 ?? (() => {})}
                setdoc5={setdoc5 ?? (() => {})}
            /> */}
      <BaseDataCheck
        check1={check1}
        setcheck1={setcheck1}
        check2={check2}
        setcheck2={setcheck2}
      />
    </BaseViolationForm>
  );
}
