import Sidebar from "../../components/common/Sidebar";
import UserScreen1 from "../user/UserScreen1";
import AdminScreen1 from "../admin/AdminScreen1";
import AdminScreen2 from "../admin/AdminScreen2";
import AdminScreen3 from "../admin/AdminScreen3";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import UserProfile from "../user/UserProfile";
import { MicrosoftAuthWrapper } from "../../components/admin/MircrosoftAuthWrapper";
import {
  useAnwaltSecuredElement,
  useMandantenSecuredElement,
  NavigateBasedOnRole,
  PortalRoutes,
} from "../../security/RoutingHelper";
import Settings from "./Settings";
import MobileBlocker from "../common/MobileBlocker";
import Help from "../admin/Help";
import AdminCasesPage from "../admin/AdminCasesPage";

function Portal() {
  return (
    <section className="grow w-full flex flex-col justify-center items-center relative bg-base-100 text-neutral font-default">
      {/* <BasePortalHeader /> */}
      <MobileBlocker />
      <div className="h-screen w-full max-w-screen-3xl relative hidden lg:flex gap-default p-2">
        <Sidebar />
        {/* WICHTIG für die nested Routes */}
        <Outlet />

        <MicrosoftAuthWrapper>
          <Routes>
            {/* Entry Point */}
            <Route
              path={PortalRoutes.common.home}
              element={<NavigateBasedOnRole />}
            />
            {/* ADMIN VIEW */}

            <Route
              path={PortalRoutes.anwalt.dashboard}
              element={useAnwaltSecuredElement({
                target: <AdminScreen1 />,
              })}
            />

            <Route
              path={PortalRoutes.anwalt.anfrage_by_id}
              element={useAnwaltSecuredElement({
                target: <AdminScreen2 />,
              })}
            />
            <Route
              path={PortalRoutes.anwalt.anfragen}
              element={useAnwaltSecuredElement({
                target: <AdminScreen2 />,
              })}
            />
            <Route
              path={PortalRoutes.anwalt.mandanten_by_id}
              element={useAnwaltSecuredElement({
                target: <AdminScreen3 />,
              })}
            />
            <Route
              path={PortalRoutes.anwalt.mandanten}
              element={<Navigate to="1" />}
            />
            <Route
              path={PortalRoutes.anwalt.cases}
              element={useAnwaltSecuredElement({
                target: <AdminCasesPage />,
              })}
            />
            <Route path={PortalRoutes.common.hilfe} element={<Help />} />
            <Route
              path={PortalRoutes.common.profil}
              element={<UserProfile />}
            />
            <Route
              path={PortalRoutes.common.settings}
              element={<Settings />}
            />
            {/* USER VIEW */}
            <Route
              path={PortalRoutes.mandant.faelle_by_id}
              element={useMandantenSecuredElement({
                target: <UserScreen1 />,
              })}
            />
            <Route
              path={PortalRoutes.mandant.faelle}
              element={<Navigate to="1" />}
            />
            <Route
              path={PortalRoutes.common.profil}
              element={useMandantenSecuredElement({
                target: <UserProfile />,
              })}
            />
          </Routes>
        </MicrosoftAuthWrapper>
      </div>
    </section>
  );
}

export default Portal;
