import { HashLink } from 'react-router-hash-link'

type BaseDataCheckType = {
    check1: boolean
    setcheck1: (e: boolean) => void
    check2: boolean
    setcheck2: (e: boolean) => void
}

export default function BaseDataCheck({
    check1,
    check2,
    setcheck1,
    setcheck2,
}: BaseDataCheckType) {
    return (
        <div className="flex flex-col gap-2">
            <div className="w-full border-b-2 border-primary pt-4" />
            <div className="flex gap-3 items-center pt-2">
                <input
                    type="checkbox"
                    defaultChecked
                    className="checkbox checkbox-accent"
                    checked={check1}
                    onChange={() => setcheck1(!check1)}
                />
                <label>
                    <span>
                        Sie haben unsere{' '}
                        <HashLink
                            to="/datenschutz"
                            className={`underline text-gray-700 visited:text-gray-400 target="_blank" rel="noopener noreferrer`}
                            target="_blank"
                            rel="noopener noreferrer">
                            Datenschutzbestimmungen
                        </HashLink>{' '}
                        gelesen *
                    </span>
                </label>
            </div>
            <div className="flex gap-3 items-center">
                <input
                    type="checkbox"
                    defaultChecked
                    className="checkbox checkbox-accent"
                    checked={check2}
                    onChange={() => setcheck2(!check2)}
                />
                <label>
                    <span>
                        Sie haben unsere{' '}
                        <HashLink
                            to="/bearbeitungsfrist"
                            className={`underline text-gray-700 visited:text-gray-400 target="_blank" rel="noopener noreferrer`}
                            target="_blank"
                            rel="noopener noreferrer">
                            Bearbeitungsfrist
                        </HashLink>{' '}
                        gelesen *
                    </span>
                </label>
            </div>
        </div>
    )
}
