import { useContext } from "react";
import { AddUserContext } from "../context/AddUserContext/AddUserContext";

type BaseTextAreaSpecialtiesType = {
  title: string;
  heightProps?: string;
  bg?: string;
  paddingTop?: string;
  value: any;
};

export default function BaseTextAreaSpecialties({
  title,
  heightProps,
  bg,
  paddingTop,
  value,
}: BaseTextAreaSpecialtiesType) {
  const addCaseContext = useContext(AddUserContext);

  return (
    <>
      <label className="form-control">
        <p className={paddingTop ? paddingTop : "pt-2"}>{title}</p>
        <textarea
          onChange={(e) =>
            addCaseContext?.changeData(2, "note", e.target.value)
          }
          value={value}
          className={`textarea textarea-primary border-2 bg-base-200 w-full ${
            heightProps ? heightProps : "max-h-32 h-full"
          } ${bg}`}
          placeholder="Vergehen..."
        ></textarea>
      </label>
    </>
  );
}
