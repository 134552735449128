import { ReactNode } from "react";
import BasePanelHeadline from "./BasePanelHeadline";

type BaseCaseFormType = {
  children: ReactNode;
  buttonRow?: ReactNode; // TODO required
};

export default function BaseCaseForm({
  children,
  buttonRow,
}: BaseCaseFormType) {
  return (
    <div className="flex flex-col overflow-y-scroll h-full w-3/5 px-4 py-8">
      <BasePanelHeadline title={"Fall melden"} />
      {children}
      <div className="grow" />
      {buttonRow}
    </div>
  );
}
