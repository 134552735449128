import React, { useContext, useEffect, useState } from "react";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import CustomerList from "../../components/admin/CustomerList";
import BasePanelChat from "../../components/base/BasePanelChat";
import { useParams } from "react-router-dom";
import BaseCustomerCaseInterface from "../../components/base/BaseCustomerCaseInterface";
import BaseCaseInterface from "../../components/base/BaseCaseInterface";
import { getAllUser } from "../../api/apiCalls";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ErrorPage from "../common/ErrorPage";
import Loading from "../../components/common/Loading";
import { caseType, userType } from "../../types/Types";
import AddDocModal from "../../components/context/ModalContext/AddDocModal";
import { ModalContext } from "../../components/context/ModalContext/ModalContext";
import BaseActionCheckModal from "../../components/base/BaseActionCheckModal";
import {
  useDeleteCaseMutation,
  useDeleteUserMutation,
  useUpdateCaseStatusMutation,
  useUpdateUserStatusMutation,
} from "../../api/services";

export default function AdminScreen3() {
  const { error, data, isLoading } = useQuery({
    queryKey: ["userData"],
    queryFn: getAllUser,
  });

  const modalCon = useContext(ModalContext);

  const { id, caseid } = useParams();

  const [selectedCustomer, setselectedCustomer] = useState<
    userType | undefined
  >();
  const [selectedCase, setselectedCase] = useState<caseType | undefined>();
  useEffect(() => {
    if (data) {
      setselectedCustomer(
        data?.find((obj: { _id: string | undefined }) => obj._id === id) ??
          data[0]
      );
    }
  }, [data, id]);
  useEffect(() => {
    setselectedCase(
      selectedCustomer?.cases?.find((obj) => obj._id === caseid) ??
        selectedCustomer?.cases[0]
    );
  }, [caseid, selectedCustomer?.cases]);

  // Anwalt - delete Case
  const queryClient = useQueryClient();
  const { mutate: deleteReq } = useDeleteCaseMutation(queryClient);
  const handleDeleteCase = () => {
    deleteReq({
      uid: selectedCustomer?._id ?? "",
      cid: selectedCase?._id ?? "",
    });
    modalCon?.trigger(0);
  };

  // Anwalt - delete User
  const { mutate: deleteUser } = useDeleteUserMutation(queryClient);
  const handleDeleteUser = () => {
    deleteUser({ uid: selectedCustomer?._id ?? "" });
    modalCon?.trigger(3);
  };

  // Anwalt - update case or user status
  const { mutate: updateCase } = useUpdateCaseStatusMutation(queryClient);
  const { mutate: updateUserStatus } = useUpdateUserStatusMutation(queryClient);
  const handleButtonClick = (status: string) => {
    updateCase({
      uid: selectedCustomer?._id ?? "",
      cid: selectedCase?._id ?? "",
      status: status,
    });
    if (selectedCustomer?.status !== "taken") {
      updateUserStatus({
        uid: selectedCustomer?._id ?? "",
        status: "taken",
      });
    }
    modalCon?.trigger(2);
  };

  return (
    <>
      {error && <ErrorPage />}
      {isLoading && <Loading />}
      {!error && !isLoading && (
        <BasePanelLayout>
          <CustomerList
            selectedId={id === "1" ? data[0]?._id : id ?? ""}
            caseId={caseid ?? ""}
            customer={data}
          />
          {caseid ? (
            <>
              <BaseCaseInterface
                ustatus={selectedCustomer?.status ?? ""}
                selectedCase={selectedCase}
                adminView
                openUploadModal={() => modalCon?.trigger(1)} // TODO Variablen Namen????
                deleteNewCase={() => modalCon?.trigger(0)}
                deleteExistingCase={() => modalCon?.trigger(5)}
                acceptCase={() => handleButtonClick("open")}
                closeCase={() => modalCon?.trigger(2)}
              />
              <BasePanelChat
                sender="rbac_anwalt"
                status={selectedCase?.status !== "open"}
                chat={selectedCase?.chat}
                uid={selectedCustomer?._id ?? ""}
                cid={selectedCase?._id ?? ""}
              />
            </>
          ) : (
            <BaseCustomerCaseInterface
              selectedCustomer={selectedCustomer}
              deleteNewUser={() => modalCon?.trigger(3)}
              deleteExistingUser={() => modalCon?.trigger(4)}
            />
          )}
          <BaseActionCheckModal
            modalId={0}
            title={"Fall Ablehnen"}
            buttonTitle={"Ablehnen"}
            text={
              "Sind Sie sicher, dass Sie den Fall ablehnen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, falls er noch nicht angenommen wurde."
            }
            function={handleDeleteCase}
          />
          <AddDocModal
            mid={1}
            uid={selectedCustomer?._id ?? ""}
            cid={selectedCase?._id ?? ""}
            modalCon={modalCon}
          />
          <BaseActionCheckModal
            modalId={2}
            title={"Fall Schließen"}
            buttonTitle={"Schließen"}
            text={
              "Sind Sie sicher, dass Sie den Fall schließen wollen? Der User wird über den Abschluss des Falls informiert. Sie können den Fall später nicht mehr öffnen oder Dateien/Nachrichten verändern."
            }
            function={() => handleButtonClick("done")}
          />
          <BaseActionCheckModal
            modalId={3}
            title={"Mandant Ablehnen"}
            buttonTitle={"Ablehnen"}
            text={
              "Sind Sie sicher, dass Sie den Mandanten ablehnen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, inklusive aller Fälle, die er eröffnet hat."
            }
            function={handleDeleteUser}
          />
          <BaseActionCheckModal
            modalId={4}
            title={"Mandant löschen"}
            buttonTitle={"Löschen"}
            text={
              "Sind Sie sicher, dass Sie den Mandanten löschen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, inklusive aller Fälle, die er eröffnet hat/hatte."
            }
            function={handleDeleteUser}
          />
          <BaseActionCheckModal
            modalId={5}
            title={"Fall löschen"}
            buttonTitle={"Löschen"}
            text={
              "Sind Sie sicher, dass Sie den Fall löschen wollen? Der Fall wird unwiederbringlich aus der Datenbank gelöscht."
            }
            function={handleDeleteCase}
          />
        </BasePanelLayout>
      )}
    </>
  );
}
