import dayjs from "dayjs";
import { newCaseType } from "../../types/Types";
import { operateWithCallOption } from "../../services/functions";
import { useNavigate } from "react-router";
import PlanMeetingModal from "./PlanMeetingModal";
import { useContext } from "react";
import { ModalContext } from "../context/ModalContext/ModalContext";
import BaseActionButton from "../base/BaseActionButton";
import { ReactComponent as CalendarICON } from "../../assets/Calendar.svg";
import { ReactComponent as PeopleICON } from "../../assets/User.svg";
import { ReactComponent as TrashICON } from "../../assets/Trash.svg";
import { ReactComponent as DocumentICON } from "../../assets/Document.svg";

type requestProfileType = {
  request?: newCaseType;
  deleteFunc: () => void;
};

export default function RequestProfile({
  request,
  deleteFunc,
}: requestProfileType) {
  const modalCon = useContext(ModalContext);
  const navigate = useNavigate();
  console.log(request);
  return (
    <>
      {request && (
        <div className="h-full w-3/5 rounded-default bg-base-300 flex flex-col p-16">
          <h3 className="text-lg font-semibold">{request?.title}</h3>
          <p className="pt-2 text-sm">
            {dayjs(request?.updated_at).format("DD.MM.YYYY")},{" "}
            {request?.first_name} {request?.last_name}
          </p>
          <p className="text-sm">
            Frist:{" "}
            {dayjs(request?.deadline).isValid()
              ? dayjs(request?.deadline).format("DD.MM.YYYY")
              : "keine Angabe"}
          </p>
          <p className="text-sm pt-4">
            {request?.first_name} {request?.last_name}
          </p>
          <p className="text-sm">
            {request?.adress?.street} {request?.adress?.nr}
          </p>
          <p className="text-sm">
            {request?.adress?.plz} {request?.adress?.city}
          </p>
          <p className="pt-4 leading-5 font-semibold">
            Telefon: {request?.tel}
          </p>
          <p className="text-sm">
            Rückruf: {operateWithCallOption(request.callOption)}
          </p>
          {request.info?.map((item, index) => (
            <p key={index} className={`text-sm ${index === 0 && "pt-4"}`}>
              {item}
            </p>
          ))}
          {request.note && (
            <p className="pt-4 text-sm">Bemerkung: {request.note}</p>
          )}
          {!request?.documents ? (
            <p className="pt-4">Keine Dokumente hochgeladen</p>
          ) : (
            request?.documents?.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between ${
                  index === 0 ? "pt-4" : "pt-1"
                }`}
              >
                <div className="flex items-center">
                  <DocumentICON className="text-primary mr-2 w-6 h-6" />
                  <p>{item.title}</p>
                </div>
              </div>
            ))
          )}
          <div className="grow" />
          <div className="flex justify-end gap-2 w-full pt-2">
            <BaseActionButton
              svg={<CalendarICON />}
              color={"btn-primary btn-outline border"}
              explanation={"Telefonat planen"}
              function={() => modalCon?.trigger(1)}
            />
            <BaseActionButton
              svg={<PeopleICON />}
              color={"btn-primary"}
              explanation={"Zum Mandanten"}
              function={() => navigate("/portal/mandanten/" + request.userId)}
            />
            <BaseActionButton
              svg={<TrashICON />}
              color={"btn-error"}
              explanation={"Anfrage löschen"}
              function={deleteFunc}
            />
          </div>
        </div>
      )}
      <PlanMeetingModal modalId={1} />
    </>
  );
}
