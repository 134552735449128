import { Outlet, Route, Routes } from "react-router-dom";
import RegistrationChooseLaw from "./pages/RegistrationChooseLaw";
import RegistrationInputData from "./pages/RegistrationInputData";
import RegistrationLanding from "./pages/RegistrationLanding";

export default function Registration() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-start">
      <Outlet />
      <Routes>
        <Route path={"/"} element={<RegistrationLanding />} />
        <Route path="/fall-melden" element={<RegistrationChooseLaw />} />
        <Route path="/fall-melden/:law" element={<RegistrationInputData />} />
      </Routes>
    </div>
  );
}
