import { useContext, useEffect, useMemo, useState } from "react";
import BaseActionCheckModal from "../../../components/base/BaseActionCheckModal";
import { ReactComponent as ArrowRight } from "./../../../assets/ArrowRight.svg";
import { ReactComponent as SEND } from "./../../../assets/PaperSend.svg";
import { ModalContext } from "../../../components/context/ModalContext/ModalContext";
import { CloseScreen } from "../SliderScreens/CloseScreen";
import { PersonalDataScreen } from "../SliderScreens/PersonalDataScreen";
import { TraficLawMainScreen } from "../laws/TraficLaw/TraficLawMainScreen";
import ControlScreen from "../SliderScreens/ControlScreen";
import { AddUserContext } from "../../../components/context/AddUserContext/AddUserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EmploymentLawMainScreen } from "../laws/EmploymentLaw/EmploymentLawMainScreen";
import { ErrorScreen } from "../SliderScreens/ErrorScreen";

export default function RegistrationInputData() {
  const { law } = useParams();
  useEffect(() => {
    addUserCon?.changeData(2, "law", law ?? "");
  }, [law]);

  const [screenLogic, setScreenLogic] = useState(1);

  // Logic to Navigate between Screens
  const handleClickBack = () => {
    if (screenLogic === 5) {
      setScreenLogic(3);
    } else {
      setScreenLogic(screenLogic - 1);
    }
  };

  // Logic to Navigate between Screens
  const handleClickWeiter = () => {
    setScreenLogic(screenLogic + 1);
  };

  // Logic to disable button if there is not input data
  const [filledOutCaseData, setfilledOutCaseData] = useState(false);
  const [filledOutPersonalData, setfilledOutPersonalData] = useState(false);
  const [filledOutCheck, setfilledOutCheck] = useState(false);

  const isButtonDisabled = useMemo(() => {
    if (screenLogic === 1) {
      return !filledOutCaseData;
    } else if (screenLogic === 2) {
      return !filledOutPersonalData;
    } else if (screenLogic === 3) {
      return !filledOutCheck;
    }
  }, [screenLogic, filledOutCaseData, filledOutPersonalData, filledOutCheck]);

  // modal context for question popups
  const modalCon = useContext(ModalContext);
  const addUserCon = useContext(AddUserContext);

  useEffect(() => {
    if (addUserCon?.status === "success") {
      setScreenLogic(4);
    }
    if (addUserCon?.status === "error") {
      setScreenLogic(5);
    }
  }, [addUserCon?.status]);

  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <Link to="/registrierung/fall-melden">
        <img src="/logo193.png" alt="Logo" className="h-32 w-32 mb-4" />
      </Link>
      <div className="flex flex-row gap-4 h-[40rem] w-1/2 items-center justify-center relative overflow-hidden">
        {/* First Screen to collect case data */}
        {law === "verkehrsrecht" && (
          <TraficLawMainScreen
            screen={screenLogic}
            setfilledOut={setfilledOutCaseData}
          />
        )}
        {law !== "verkehrsrecht" && (
          <EmploymentLawMainScreen
            screen={screenLogic}
            setfilledOut={setfilledOutCaseData}
          />
        )}
        {/* Secon Screen to collect personal data */}
        <PersonalDataScreen
          screen={screenLogic}
          setfilledOut={setfilledOutPersonalData}
        />
        <ControlScreen
          screen={screenLogic}
          setfilledOut={setfilledOutCheck}
          status={addUserCon?.status === "pending"}
        />
        <CloseScreen screen={screenLogic} setfilledOut={setfilledOutCaseData} />
        <ErrorScreen screen={screenLogic} setfilledOut={setfilledOutCaseData} />
      </div>
      <div className="flex flex-row gap-1 items-center pt-1">
        <div
          className={`rounded-full ${
            screenLogic === 1
              ? "bg-primary h-3 w-3"
              : "border-neutral border-2 h-2 w-2"
          }`}
        />
        <div
          className={`rounded-full ${
            screenLogic === 2
              ? "bg-primary h-3 w-3"
              : "border-neutral border-2 h-2 w-2"
          }`}
        />
        <div
          className={`rounded-full ${
            screenLogic === 3
              ? "bg-primary h-3 w-3"
              : "border-neutral border-2 h-2 w-2"
          }`}
        />
        <div
          className={`rounded-full ${
            screenLogic === 4
              ? "bg-primary h-3 w-3"
              : "border-neutral border-2 h-2 w-2"
          }`}
        />
      </div>
      <div className="flex w-1/2 h-fit justify-between">
        {screenLogic !== 4 && (
          <>
            <button
              className="btn btn-ghost"
              // disabled={screenLogic === 1}
              onClick={
                screenLogic === 1
                  ? () => navigate("/registrierung/fall-melden")
                  : () => handleClickBack()
              }
            >
              <ArrowRight className="w-5 h-5 rotate-180" />
              Zurück
            </button>
            {screenLogic < 3 ? (
              <button
                className="btn btn-primary"
                disabled={isButtonDisabled}
                onClick={handleClickWeiter}
              >
                Weiter
                <ArrowRight className="w-5 h-5" />
              </button>
            ) : (
              <>
                {screenLogic !== 5 && (
                  <button
                    className="btn btn-success"
                    disabled={isButtonDisabled}
                    onClick={addUserCon?.addData}
                  >
                    Absenden
                    <SEND className="w-5 h-5" />
                  </button>
                )}
              </>
            )}
          </>
        )}
      </div>
      <BaseActionCheckModal
        modalId={1}
        title={"Warum erfragen wir das?"}
        buttonTitle={"Verstanden!"}
        text={
          "Wenn wir Rückfragen zu Ihren Angaben oder Ihrem Fall haben, wollen wir Sie gern telefonisch kontaktieren. Damit stellen wir einen reibungslosen Ablauf sicher."
        }
        function={() => modalCon?.trigger(1)}
      />
      <BaseActionCheckModal
        modalId={2}
        title={"Warum erfragen wir das?"}
        buttonTitle={"Verstanden!"}
        text={
          "Im Falle Sie haben keine Rechtsschutzversicherung sind wir verpflichtet, Sie über das Anfallen möglicher Kosten zu informieren."
        }
        function={() => modalCon?.trigger(2)}
      />
    </div>
  );
}
