import dayjs from "dayjs";
import { userType } from "../../types/Types";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateUserStatusMutation } from "../../api/services";
import { operateWithCallOption } from "../../services/functions";
import PlanMeetingModal from "../admin/PlanMeetingModal";
import { ModalContext } from "../context/ModalContext/ModalContext";
import { useContext } from "react";
import BaseActionButton from "./BaseActionButton";
import { ReactComponent as CALENDAR } from "../../assets/Calendar.svg";
import { ReactComponent as APPROVE } from "../../assets/Approve.svg";
import { ReactComponent as CLOCK } from "../../assets/Clock.svg";
import { ReactComponent as TRASH } from "../../assets/Trash.svg";

type CaseInterfaceType = {
  selectedCustomer?: userType;
  deleteNewUser: () => void;
  deleteExistingUser: () => void;
};

export default function CustomerCaseInterface({
  selectedCustomer,
  deleteNewUser,
  deleteExistingUser,
}: CaseInterfaceType) {
  const queryClient = useQueryClient();
  const { mutate: updateCase } = useUpdateUserStatusMutation(queryClient);
  const handleButtonClick = (status: string) => {
    updateCase({ uid: selectedCustomer?._id ?? "", status: status });
  };

  const modalCon = useContext(ModalContext);

  return (
    <div className="grow flex flex-col p-16 rounded-default bg-base-300 gap-4">
      {selectedCustomer && (
        <>
          <div className="flex flex-col">
            <p className="text-lg font-semibold">
              {selectedCustomer?.personal.first_name}{" "}
              {selectedCustomer?.personal.last_name}
            </p>
            <p className="text-sm">
              Letztes Update:
              {" " +
                dayjs(selectedCustomer?.updated_at).format(
                  "DD.MM.YYYY HH:mm"
                )}{" "}
              Uhr
            </p>
            <p className="pt-4">
              {selectedCustomer?.personal.first_name}{" "}
              {selectedCustomer?.personal.last_name}
            </p>
            <p className="pt-0">
              {selectedCustomer?.personal.adress?.street}{" "}
              {selectedCustomer?.personal.adress?.nr}
            </p>
            <p className="pt-0">
              {selectedCustomer?.personal.adress?.plz}{" "}
              {selectedCustomer?.personal.adress?.city}
            </p>
            <p className="text-lg font-semibold pt-4">
              Telefon: {selectedCustomer?.personal.tel}
            </p>
            <p>
              Rückruf:{" "}
              {operateWithCallOption(selectedCustomer.personal.callOption)}
            </p>
          </div>
          <div className="grow" />
          <div className="flex justify-end gap-2">
            {selectedCustomer.status !== "taken" && (
              <>
                <BaseActionButton
                  svg={<CALENDAR />}
                  color="btn-primary btn-outline border"
                  function={() => modalCon?.trigger(11)}
                  explanation="Telefonatplanung"
                />
                {selectedCustomer?.cases.every(
                  (obj) => obj.status === "new"
                ) && (
                  <BaseActionButton
                    svg={<APPROVE />}
                    color="btn-success"
                    function={() => handleButtonClick("taken")}
                    explanation="Mandantannahme"
                  />
                )}

                {selectedCustomer?.status === "new" && (
                  <BaseActionButton
                    svg={<CLOCK />}
                    color="btn-warning"
                    function={() => handleButtonClick("waitlist")}
                    explanation="Warteliste"
                  />
                )}
                <BaseActionButton
                  svg={<TRASH />}
                  color="btn-error"
                  function={deleteNewUser}
                  explanation="Löschung"
                />
              </>
            )}
            {selectedCustomer.status === "taken" && (
              <BaseActionButton
                svg={<TRASH />}
                color={"btn-accent btn-outline border"}
                explanation={"Mandant löschen"}
                function={deleteExistingUser}
              />
            )}
          </div>
        </>
      )}
      <PlanMeetingModal modalId={11} />
    </div>
  );
}
