import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ModalContextProvider } from "./components/context/ModalContext/ModalContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Loading from "./components/common/Loading";
import Keycloak from "keycloak-js";
import { eventLogger, tokenLogger } from "./security/kcEventListener";
import { AddUserContextProvider } from "./components/context/AddUserContext/AddUserContext";
import { LightDarkModeContextProvider } from "./components/context/LightDarkModeContext/LightDarkModeContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const keycloakConfig = new Keycloak({
  url: "https://auth.mm.wicode.io",
  realm: "wicode-attorney-software",
  clientId: "react-frontend",
});

root.render(
  <QueryClientProvider client={new QueryClient()}>
    <LightDarkModeContextProvider>
      <AddUserContextProvider>
        <ModalContextProvider>
          <BrowserRouter>
            <ReactKeycloakProvider
              authClient={keycloakConfig}
              LoadingComponent={<Loading />}
              autoRefreshToken={true}
              onEvent={eventLogger}
              onTokens={tokenLogger}
              initOptions={{
                onLoad: "check-sso",
              }}
            >
              <App />
            </ReactKeycloakProvider>
          </BrowserRouter>
        </ModalContextProvider>
      </AddUserContextProvider>
    </LightDarkModeContextProvider>
  </QueryClientProvider>
);
