import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";

export default function RegistrationLanding() {
  const { keycloak } = useKeycloak();
  return (
    <section className="w-full relative h-screen flex flex-col items-center justify-center">
      <div className="h-[200%] w-2/3 -left-64 bg-primary absolute rotate-12" />
      <img
        alt="User pastes in data"
        src="/img/landing/illustration-datainput.jpg"
        className="absolute top-12 right-48 max-h-96 max-w-[24rem] opacity-60 z-0"
      />
      <div className="w-[40rem] absolute top-auto left-48 h-1/2 z-10 bg-base-100 p-8 rounded-default shadow-lg items-center flex flex-col justify-center">
        <p className="text-5xl font-bold underline tracking-wider text-center uppercase text-neutral font-headline">
          Das Anwaltsportal
        </p>
        <p className="text-xl pt-4 px-4 text-justify">
          Willkommen auf dem Portal für digitale Mandanten- und Fallarbeit. Die
          Lösung für moderne Anwälte und Kanzleien.
        </p>
        <p className="text-base pt-4 px-4 font-medium text-center">
          Auf diesem Weg kommunizieren Anwälte und Mandanten digital, erreichen
          die maximale Zeit- und Erfolgseffizienz bei der Bearbeitung
          verschiedenster Fälle.
        </p>
        <div className="flex flex-row justify-center gap-4 w-full pt-6">
          <Link
            to="/registrierung/fall-melden"
            className="btn btn-primary btn-lg w-1/3"
          >
            Fall melden
          </Link>
          <button
            className="btn btn-primary btn-lg w-1/3"
            onClick={() =>
              keycloak.login({
                redirectUri: window.location.origin + "/portal",
              })
            }
          >
            Login
          </button>
        </div>
      </div>
      <img
        alt="User pastes in data"
        src="/img/landing/illustration-success.jpg"
        className="absolute bottom-12 right-96 max-h-96 max-w-[24rem] opacity-60 z-0"
      />
    </section>
  );
}
