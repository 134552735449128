import CustomerList from "../../components/admin/CustomerList";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import NewsList from "../../components/admin/NewsList";
import RequestList from "../../components/admin/RequestList";
import CallbackList from "../../components/admin/CallBackList";
import { getAllNews, getAllUser, getNewCases } from "../../api/apiCalls";
import { useQuery } from "@tanstack/react-query";
import ErrorPage from "../../pages/common/ErrorPage";
import { MicrosoftAuthWrapper } from "../../components/admin/MircrosoftAuthWrapper";
import Loading from "../../components/common/Loading";

export default function AdminScreen1() {
  const { error, data, isLoading } = useQuery({
    queryKey: ["userData"],
    queryFn: getAllUser,
  });
  const {
    error: errorNews,
    data: dataNews,
    isLoading: isLoadingNews,
  } = useQuery({
    queryKey: ["newsData"],
    queryFn: getAllNews,
  });
  const {
    error: errorReq,
    data: dataReq,
    isLoading: isLoadingReq,
  } = useQuery({
    queryKey: ["newCasesData"],
    queryFn: getNewCases,
  });

  return (
    <>
      {(error || errorReq || errorNews) && <ErrorPage />}
      {(isLoading || isLoadingReq || isLoadingNews) && <Loading />}
      {!isLoading && !error && data && dataNews && (
        <BasePanelLayout>
          <div className="flex flex-col w-full gap-default">
            <RequestList
              classProps="max-h-[50%] h-full pb-8 w-full "
              requests={dataReq && dataReq?.reverse().slice(0, 4)}
            />
            <div className="flex flex-row w-full h-full gap-default">
              <NewsList news={dataNews} />
              <MicrosoftAuthWrapper>
                <CallbackList />
              </MicrosoftAuthWrapper>
            </div>
          </div>
          <CustomerList small customer={data} />
        </BasePanelLayout>
      )}
    </>
  );
}
