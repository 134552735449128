
import React, { ReactNode, createContext, useState } from 'react'

export type ModalContextProps = {
    open: boolean;
    animation: boolean;
    trigger: (nr: number) => void;
    modalId: number;
}

export type ModalContextProviderProps = {
    children: ReactNode
}

export const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children } : ModalContextProviderProps ) => {
    
    const [open, setOpen] = useState(false)
    const [modalId, setmodalId] = useState<number>(0)
    const [animation, setAnimation] = useState(false)

    const trigger = (nr: number) => {
        if (!open) {
            setmodalId(nr)
            setOpen(!open)
            setAnimation(!animation)
        }
        else {
            setAnimation(!animation)
            setTimeout(() => {
                setOpen(!open)
                setmodalId(nr)
            }, 250)
        }
    }

    return(
        <ModalContext.Provider  value={{ open, animation, trigger, modalId }}>
            {children}
        </ModalContext.Provider>
    )
}
