import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import BasePanelHeadline from "../base/BasePanelHeadline";
import { msTopenScopes } from "./MircrosoftAuthWrapper";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { CalendarEntries } from "./CalendarEntries";
import { Tooltip } from "react-daisyui";

export default function CallbackList() {
  const { instance } = useMsal();

  const loginToMsal = async () =>
    await instance
      .loginPopup({
        scopes: msTopenScopes,
        redirectUri: window.location.origin,
      })
      .catch((e) => {
        console.error(e);
      });

  const logout = async () =>
    await instance
      .logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => {
          return false;
        },
      })
      .catch((e) => {
        console.error(e);
      });

  return (
    <div className="flex flex-col py-4 px-16 rounded-default h-full w-full bg-base-300 relative">
      <BasePanelHeadline title={"Rückrufe"} />
      <UnauthenticatedTemplate>
        <div className="w-72 m-auto">
          <MicrosoftLoginButton onClick={loginToMsal} align="center">
            <span>Anmelden bei Outlook</span>
          </MicrosoftLoginButton>
        </div>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Tooltip
          className="absolute top-8 right-12 h-6 w-6"
          message="Bei Outlook abmelden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-secondary hover:cursor-pointer"
            onClick={logout}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
            />
          </svg>
        </Tooltip>
        <CalendarEntries />
      </AuthenticatedTemplate>
    </div>
  );
}
