import BaseButton from "../../components/base/BaseButton";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import BaseSearchInput from "../../components/base/BaseSearchInput";
import { ReactComponent as FilterICON } from "../../assets/FilterSettings.svg";
import { ReactComponent as ArrowICON } from "../../assets/ArrowDown.svg";

export default function AdminCasesPage() {
  return (
    <BasePanelLayout col>
      {/* Horizontal Line */}
      <div className="bg-base-300 rounded-default w-full p-4 flex items-center justify-between">
        <BaseSearchInput placeholder="Fall suchen..." />
        <div className="flex gap-4">
          <BaseButton
            text="Sortieren"
            icon={<ArrowICON className="w-5 h-5" />}
          />
          <BaseButton text="Filter" icon={<FilterICON className="w-6 h-6" />} />
        </div>
      </div>
      <div className="w-full h-full flex gap-default ">
        {/* Left List */}
        <div className="bg-base-300 rounded-default w-1/3 h-full overflow-y-scroll"></div>
        {/* Right Display */}
        <div className="bg-base-300 rounded-default grow h-full overflow-y-scroll"></div>
      </div>
    </BasePanelLayout>
  );
}
