import { useMsal } from '@azure/msal-react'
import { useGetMSEventsQuery } from '../../api/microsoft/services'
import { useEffect, useMemo, useState } from 'react'
import { msTopenScopes } from './MircrosoftAuthWrapper'
import {
    extractAndFormatEventTime,
    groupEventsByDate,
} from '../../services/functions'
import { AiOutlineLoading } from 'react-icons/ai'

export const CalendarEntries = () => {
    const { instance } = useMsal()
    const [token, setToken] = useState<string | undefined>()
    const { data, error, isLoading, errorUpdateCount } =
        useGetMSEventsQuery(token)

    const converted = useMemo(() => {
        if (data) {
            return groupEventsByDate(data)
        }
        return {}
    }, [data])

    useEffect(() => {
        if (errorUpdateCount > 1) return
        const account = instance.getActiveAccount()
        if (account) {
            instance
                .acquireTokenSilent({
                    account: account,
                    scopes: msTopenScopes,
                })
                .then((res) => {
                    setToken(res.accessToken)
                })
        }
    }, [instance, error, errorUpdateCount])

    if (isLoading) return <AiOutlineLoading />

    return (
        <div className="flex flex-col h-full w-full overflow-y-scroll">
            {Object.keys(converted).map((dateKey) => (
                <div key={dateKey} className="flex flex-col pt-4">
                    <h4 className="text-xl uppercase text-primary">
                        {dateKey}
                    </h4>

                    <ul>
                        {converted[dateKey].map((event) => (
                            <li
                                key={event.id}
                                className="flex flex-row w-full py-1 gap-2 font-medium text-base items-center">
                                <input
                                    type="checkbox"
                                    defaultChecked={false}
                                    className="checkbox"
                                />
                                {extractAndFormatEventTime(event) + ' Uhr'}
                                {' - '}
                                {event.displayName}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}
