import BasePanelLayout from "../../components/base/BasePanelLayout";
import { ReactComponent as HelpICON } from "../../assets/QuestionMark.svg";
import { ReactComponent as PhoneICON } from "../../assets/Phone.svg";
import { motion } from "framer-motion";
import BasePanelHeadline from "../../components/base/BasePanelHeadline";
import BaseInputField from "../../components/base/BaseInputField";
import BaseTextAreaSpecialties from "../../components/base/BaseTextAreaSpecialties";
import BaseButton from "../../components/base/BaseButton";

export default function Help() {
  return (
    <BasePanelLayout>
      <div className="h-full w-full flex flex-col bg-base-300 rounded-default p-4 pt-12">
        <BaseInputField
          label={"Anliegen"}
          bigText
          type={"text"}
          placeholder={"Fehlfunktion, Error, Bug..."}
          bg="bg-base-300"
          width="w-full"
        />
        <BaseTextAreaSpecialties
          title={"Genauere Beschreibung"}
          heightProps="grow h-[40rem] mb-4"
          bg="bg-base-300"
          value={undefined}
        />
        <BaseButton text={"Senden"} icon={undefined} />
      </div>
      <div className="flex items-center justify-center w-1/2 flex-col px-16">
        <motion.div
          animate={{
            rotateY: 360,
          }}
          transition={{
            ease: "linear",
            repeat: Infinity,
            repeatDelay: 0,
            duration: 9,
          }}
        >
          <HelpICON className="h-96 w-96 text-primary" />
        </motion.div>
        <BasePanelHeadline title={"Hilfe gesucht?"} />
        <p className="text-sm pt-2 pb-8">
          Sie haben ein Problem, eine Fehlfunktion, einen Bug oder ein anderes
          Anliegen? Beschreiben Sie uns kurz Ihr Problem und wir werden uns
          schnellstmöglich um Ihr Anliegen kümmern.
        </p>
        <a
          href="tel:0123456789"
          // href="tel:015128347347"
          className="p-4 bg-base-200 shadow-xl h-fit w-full rounded-default flex justify-between items-center hover:shadow-2xl"
        >
          <PhoneICON className="text-primary h-8 w-8" />
          <div className="flex items-start flex-col justify-start w-[80%]">
            <p className="text-xs w-full">
              Oder Kontaktieren Sie uns telefonisch
              <br />
              <span className="text-sm underline font-bold text-secondary">
                +49 123 45656789
                {/* +49 151 28347347 */}
              </span>
            </p>
            <p className="text-xs">
              <span className="underline">Hinweis:</span> Bitte nutzen Sie die
              Telefon-Option nur im Notfall und bei dringenden Anliegen.
            </p>
          </div>
        </a>
      </div>
    </BasePanelLayout>
  );
}
