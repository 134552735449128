import { useContext, useEffect } from "react";
import { AddUserContext } from "../../../../components/context/AddUserContext/AddUserContext";
import BaseViolationForm from "../../../../components/base/BaseViolationForm";
import BaseTextAreaSpecialties from "../../../../components/base/BaseTextAreaSpecialties";
import BaseInputField from "../../../../components/base/BaseInputField";
import BaseDocsSelection from "../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../Types";

export default function BaseDataGatheringScreen(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);
  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", props.role ?? "Anderes");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      addCaseContext?.caseData?.note !== "" &&
      addCaseContext?.caseData?.note !== undefined
    ) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <BaseTextAreaSpecialties
        title={"Bitte beschreiben Sie kurz Ihr Anliegen: *"}
        heightProps="h-80"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={
          "Falls der Fall eine Bearbeitungsfrist oder Deadline hat, bitte hier eintragen: "
        }
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline ?? ""}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection
        title="Falls Sie Dokumente besitzen oder erhalten haben, die den Fall betreffen, bitter hier hochladen:"
        hideFileSelection
        extend={false}
      />
    </BaseViolationForm>
  );
}
