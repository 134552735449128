import { useContext, useState } from "react";
import BaseModal from "../context/ModalContext/BaseModal";
import BaseInputField from "../base/BaseInputField";
import { useCreateMSEventMutation } from "../../api/microsoft/services";
import { useQueryClient } from "@tanstack/react-query";
import { userType } from "../../types/Types";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { msTopenScopes } from "./MircrosoftAuthWrapper";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { ModalContext } from "../context/ModalContext/ModalContext";

type PlanMeetingModalType = {
  modalId: number;
  customer?: userType;
};

export default function PlanMeetingModal({
  modalId,
  customer,
}: PlanMeetingModalType) {
  const { instance } = useMsal();
  const qc = useQueryClient();
  const { mutateAsync, isError } = useCreateMSEventMutation(
    qc,
    () => {
      clearInputs();
      modalcontext?.trigger(modalId);
    },
    (error) => {
      console.error("Failed to plan meeting:", error);
    }
  );

  const [topic, settopic] = useState<string>("");
  const [date, setdate] = useState<string>("");
  const [start, setstart] = useState<string>("");
  const [end, setend] = useState<string>("");
  const modalcontext = useContext(ModalContext);
  const clearInputs = () => {
    settopic("");
    setdate("");
    setstart("");
    setend("");
  };

  const msalToken = async (): Promise<string | undefined> => {
    const account = instance.getActiveAccount();
    if (account) {
      return instance
        .acquireTokenSilent({
          account: account,
          scopes: msTopenScopes,
        })
        .then((res) => {
          return res.accessToken;
        })
        .catch((e) => {
          console.error(e);
          return undefined;
        });
    }
    return undefined;
  };

  const handleSubmit = async () => {
    if (!customer) return;
    await mutateAsync({
      tokenString: await msalToken(),
      customer: customer as userType,
      newEvent: {
        subject: topic,
        start: {
          dateTime: new Date(`${date}T${start}:00`),
          timeZone: "Europe/Berlin",
        },
        end: {
          dateTime: new Date(`${date}T${end}:00`),
          timeZone: "Europe/Berlin",
        },
      },
    });
  };

  const loginToMsal = async () =>
    await instance
      .loginPopup({
        scopes: msTopenScopes,
        redirectUri: window.location.origin,
      })
      .catch((e) => {
        console.error(e);
      });

  return (
    <BaseModal
      hiddenCross
      size
      modalId={modalId}
      title={"Termin planen"}
      bottomRow={
        <AuthenticatedTemplate>
          <div className="flex flex-row w-full justify-between border-t mt-2 pt-2">
            {isError && (
              <p className="pt-4 text-error text-wrap">
                Die Kommunikation mit Outlook ist fehlgeschlagen.
              </p>
            )}
            <button
              onClick={handleSubmit}
              disabled={
                topic === "" || date === "" || start === "" || end === ""
              }
              className="btn btn-success ml-auto"
            >
              Planen
            </button>
          </div>
        </AuthenticatedTemplate>
      }
    >
      <AuthenticatedTemplate>
        <div className="flex gap-4 w-full pt-2 px-4">
          <BaseInputField
            label={"Thema"}
            type={"text"}
            placeholder={"Thema"}
            value={topic}
            setvalue={settopic}
          />
          <BaseInputField
            label={"Datum"}
            type={"date"}
            placeholder={"Thema"}
            value={date}
            setvalue={setdate}
          />
        </div>
        <div className="flex gap-4 w-full pt-2 px-4">
          <BaseInputField
            label={"Startzeit"}
            type={"time"}
            placeholder={"Thema"}
            value={start}
            setvalue={setstart}
          />
          <BaseInputField
            label={"Endzeit"}
            type={"time"}
            placeholder={""}
            value={end}
            setvalue={setend}
          />
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="w-72 my-20 mx-16">
          <MicrosoftLoginButton onClick={loginToMsal} align="center">
            <span>Anmelden bei Outlook</span>
          </MicrosoftLoginButton>
        </div>
      </UnauthenticatedTemplate>
    </BaseModal>
  );
}
