type BaseInputFieldType = {
    label: string
    type: string
    placeholder: string
    value: string | number
    classProps?: string
    setvalue: (e: any) => void
}
export default function BaseInputObjectField(props: BaseInputFieldType) {
    return (
        <label className="form-control w-full">
            <div className="label text-sm">
                <span className="label-text">{props.label}</span>
            </div>
            <input
                type={props.type}
                placeholder={props.placeholder}
                className={`input input-bordered input-secondary ${
                    props.classProps ?? 'w-64'
                }`}
                value={props.value}
                onChange={props.setvalue}
            />
        </label>
    )
}
