import { motion } from "framer-motion";
import BaseInputField from "../../../components/base/BaseInputField";
import BasePanelHeadline from "../../../components/base/BasePanelHeadline";
import { useContext, useEffect } from "react";
import { ModalContext } from "../../../components/context/ModalContext/ModalContext";
import { ScreenType } from "../Types";
import { AddUserContext } from "../../../components/context/AddUserContext/AddUserContext";
import { checkForValue } from "../../../services/functions";

export function PersonalDataScreen(props: ScreenType) {
  const modalCon = useContext(ModalContext);
  const addCaseContext = useContext(AddUserContext);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      checkForValue(addCaseContext?.personalData?.first_name) &&
      checkForValue(addCaseContext?.personalData?.last_name) &&
      checkForValue(addCaseContext?.personalData?.mail) &&
      checkForValue(addCaseContext?.personalData?.tel) &&
      checkForValue(addCaseContext?.personalData?.adress?.street) &&
      checkForValue(addCaseContext?.personalData?.adress?.nr) &&
      checkForValue(addCaseContext?.personalData?.adress?.plz) &&
      checkForValue(addCaseContext?.personalData?.adress?.city) &&
      checkForValue(addCaseContext?.personalData?.callOption) &&
      checkForValue(addCaseContext?.personalData?.insurance)
    ) {
      props.setfilledOut(true);
    }
  }, [addCaseContext?.personalData, props]);

  return (
    <motion.div
      initial={{ x: props.screen < 2 ? "120%" : 0 }}
      animate={{
        x: props.screen === 2 ? 0 : props.screen > 2 ? "-120%" : undefined,
      }}
      transition={{
        type: "ease",
      }}
      className="bg-base-200 h-full w-full absolute flex flex-col p-4 rounded-default overflow-y-scroll pl-2"
    >
      <BasePanelHeadline title={"Persönliche Daten"} classProps="pb-4" />
      <div className="flex flex-wrap gap-x-4 gap-y-2 w-full justify-center">
        <BaseInputField
          label={"Vorname"}
          type={"text"}
          placeholder={"Max"}
          attribut="first_name"
          value={addCaseContext?.personalData?.first_name}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Nachname"}
          type={"text"}
          placeholder={"Mustermann"}
          attribut="last_name"
          value={addCaseContext?.personalData?.last_name}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"E-Mail"}
          type={"text"}
          placeholder={"max.mustermann@mail.de"}
          attribut="mail"
          value={addCaseContext?.personalData?.mail}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Telefonnummer"}
          type={"text"}
          placeholder={"+49123456789"}
          attribut="tel"
          value={addCaseContext?.personalData?.tel}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Straße"}
          type={"text"}
          placeholder={"Hauptstraße"}
          attribut="street"
          stage={3}
          value={addCaseContext?.personalData?.adress?.street}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Nummer"}
          type={"text"}
          placeholder={"1"}
          attribut="nr"
          stage={3}
          value={addCaseContext?.personalData?.adress?.nr}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Postleihzahl"}
          type={"text"}
          placeholder={"01234"}
          attribut="plz"
          stage={3}
          value={addCaseContext?.personalData?.adress?.plz}
          bg="bg-base-200"
        />
        <BaseInputField
          label={"Stadt"}
          type={"text"}
          placeholder={"Berlin"}
          attribut="city"
          stage={3}
          value={addCaseContext?.personalData?.adress?.city}
          bg="bg-base-200"
        />
      </div>
      <BasePanelHeadline
        title={"Erreichbarkeit"}
        classProps="pt-12 pb-4"
        tooltip={{
          badge: true,
          func: () => modalCon?.trigger(1),
        }}
      />
      <div className="flex flex-wrap w-full justify-evenly gap-4">
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name="erreichbarkeit"
            className="radio radio-primary border-2"
            checked={addCaseContext?.personalData?.callOption === 1}
            onChange={() => addCaseContext?.changeData(1, "callOption", 1)}
          />
          <label>08:00 Uhr - 11:00 Uhr</label>
        </div>
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name="erreichbarkeit"
            className="radio radio-primary border-2"
            checked={addCaseContext?.personalData?.callOption === 2}
            onChange={() => addCaseContext?.changeData(1, "callOption", 2)}
          />
          <label>11:00 Uhr - 14:00 Uhr</label>
        </div>
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name="erreichbarkeit"
            className="radio radio-primary border-2"
            checked={addCaseContext?.personalData?.callOption === 3}
            onChange={() => addCaseContext?.changeData(1, "callOption", 3)}
          />
          <label>14:00 Uhr - 17:00 Uhr</label>
        </div>
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name="erreichbarkeit"
            className="radio radio-primary border-2"
            checked={addCaseContext?.personalData?.callOption === 4}
            onChange={() => addCaseContext?.changeData(1, "callOption", 4)}
          />
          <label>17:00 Uhr - 20:00 Uhr</label>
        </div>
      </div>
      <BasePanelHeadline
        title={"Rechtsschutzversicherung"}
        classProps="pt-12 pb-4"
        tooltip={{
          badge: true,
          func: () => modalCon?.trigger(2),
        }}
      />
      <div className="flex flex-row justify-center gap-8">
        <div className="flex gap-3 items-center">
          <label className="w-40 text-end">Vorhanden</label>
          <input
            type="radio"
            name="rechtsschutz"
            className="radio radio-primary border-2"
            checked={
              addCaseContext?.personalData?.insurance &&
              addCaseContext?.personalData?.insurance !== undefined
            }
            onChange={() => addCaseContext?.changeData(1, "insurance", true)}
          />
        </div>
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name="rechtsschutz"
            className="radio radio-primary border-2"
            checked={
              !addCaseContext?.personalData?.insurance &&
              addCaseContext?.personalData?.insurance !== undefined
            }
            onChange={() => addCaseContext?.changeData(1, "insurance", false)}
          />
          <label className="w-40">Nicht vorhanden</label>
        </div>
      </div>
    </motion.div>
  );
}
