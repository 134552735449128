import { useContext, useEffect } from "react";
import BaseToggle from "../../../../../components/base/BaseToggle";
import BaseViolationForm from "../../../../../components/base/BaseViolationForm";
import { AddUserContext } from "../../../../../components/context/AddUserContext/AddUserContext";
import BaseDocsSelection from "../../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../../Types";
import BaseInputField from "../../../../../components/base/BaseInputField";
import BaseTextAreaSpecialties from "../../../../../components/base/BaseTextAreaSpecialties";
import { checkForValue } from "../../../../../services/functions";

export default function RedlightViolation(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);
  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", "Rotlichtverstoß");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      checkForValue(addCaseContext?.caseData?.toggle1) &&
      checkForValue(addCaseContext?.caseData?.toggle2) &&
      checkForValue(addCaseContext?.caseData?.toggle3) &&
      checkForValue(addCaseContext?.caseData?.toggle4)
    ) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <BaseToggle
        value1={"Ampel weniger als 1sek rot"}
        value2={"Ampel mehr als 1sek rot"}
        group="redlighttime"
        title="Wie lang war die Ampel bereits rot? *"
        label1="weniger als 1 Sekunde"
        label2="mehr als 1 Sekunde"
        checked={addCaseContext?.caseData?.toggle1}
        toggle={"toggle1"}
      />
      <BaseToggle
        value1={"Unfall: Ja"}
        value2={"Unfall: Nein"}
        group="crash"
        title="Kam es zu einem Unfall? *"
        label1="Ja"
        label2="Nein"
        checked={addCaseContext?.caseData?.toggle2}
        toggle={"toggle2"}
      />
      <BaseToggle
        value1={"Person gefährdet: Ja"}
        value2={"Person gefährdet: Nein"}
        group="personindanger"
        title="Wurde eine Person gefährdet? *"
        label1="Ja"
        label2="Nein"
        checked={addCaseContext?.caseData?.toggle3}
        toggle={"toggle3"}
      />
      <BaseToggle
        value1={"Beruflich Angewiesen: Ja"}
        value2={"Beruflich Angewiesen: Nein"}
        group="necessary"
        title="Sind Sie beruflich auf den Führerschein angewiesen? *"
        label1="Ja"
        label2="Nein"
        checked={addCaseContext?.caseData?.toggle4}
        toggle={"toggle4"}
      />
      <BaseTextAreaSpecialties
        title={"Bemerkung"}
        heightProps="max-h-32 h-full"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={"Frist des Falls"}
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection extend={false} />
    </BaseViolationForm>
  );
}
