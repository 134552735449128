import React, { useContext, useState } from "react";
import { AddUserContext } from "../context/AddUserContext/AddUserContext";
import { ReactComponent as ADDCIRCLE } from "../../assets/AddCircle.svg";
import { ReactComponent as CLOSECIRCLE } from "../../assets/CloseCircle.svg";
import { BaseDocsSelectionType } from "../types";

export default function BaseDocsSelection(props: BaseDocsSelectionType) {
  const addCaseContext = useContext(AddUserContext);

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    func: number
  ) => {
    if (func === 1) {
      addCaseContext?.changeData(
        2,
        "doc1",
        event.target.checked ? "Zeugenfragebogen erhalten" : ""
      );
    } else if (func === 2) {
      addCaseContext?.changeData(
        2,
        "doc2",
        event.target.checked ? "Anhörungsbogen erhalten" : ""
      );
    } else if (func === 3) {
      addCaseContext?.changeData(
        2,
        "doc3",
        event.target.checked ? "Bußgeldbescheid erhalten" : ""
      );
    } else if (func === 4) {
      addCaseContext?.changeData(
        2,
        "doc4",
        event.target.checked ? "Strafbefehl erhalten" : ""
      );
    } else if (func === 5) {
      addCaseContext?.changeData(
        2,
        "doc5",
        event.target.checked ? "Anklageschrift erhalten" : ""
      );
    }
  };
  const [documentcount, setdocumentcount] = useState<number>(1);

  // add doc to doc-array-useState
  const addDocument = () => {
    addCaseContext?.setdocuments((prevDocuments: any[]) => [
      ...prevDocuments,
      new File([], ""),
    ]);
  };

  // remove Doc from useState-array
  const removeDocument = (indexToRemove: number) => {
    setdocumentcount(documentcount - 1);
    addCaseContext?.setdocuments((prevValues: any) =>
      prevValues.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleDocInputChange = (
    index: number,
    key: string,
    newValue?: string | File | null
  ) => {
    addCaseContext?.setdocuments((prevDocuments: any[]) =>
      prevDocuments.map((item, idx) => {
        return idx === index ? { ...item, [key]: newValue } : item;
      })
    );
  };

  return (
    <>
      <p className="pt-8">
        {props.title
          ? props.title
          : "Welche Dokumente wurden Ihnen zugestellt?"}
      </p>
      {!props.hideFileSelection && (
        <>
          <div className="flex gap-3 pt-1">
            <input
              type="checkbox"
              className="checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeCheckbox(e, 1)
              }
            />
            <label>Zeugenfragebogen</label>
          </div>
          <div className="flex gap-3 pt-1">
            <input
              type="checkbox"
              className="checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeCheckbox(e, 2)
              }
            />
            <label>Anhörungsbogen</label>
          </div>
          <div className="flex gap-3 pt-1">
            <input
              type="checkbox"
              className="checkbox"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeCheckbox(e, 3)
              }
            />
            <label>Bußgeldbescheid</label>
          </div>
          {props.extend && (
            <>
              <div className="flex gap-3 pt-1">
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeCheckbox(e, 4)
                  }
                />
                <label>Strafbefehl</label>
              </div>
              <div className="flex gap-3 pt-1">
                <input
                  type="checkbox"
                  className="checkbox "
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeCheckbox(e, 5)
                  }
                />
                <label>Anklageschrift</label>
              </div>
            </>
          )}
          <div className="w-full border-b-2 border-primary pt-4 mb-2 " />
        </>
      )}
      <div className="flex flex-wrap gap-x-16">
        {addCaseContext?.documents?.map((item: any, index: number) => (
          <div key={index} className="flex flex-row w-fit gap-2 items-end">
            <div className="flex mt-2 items-center gap-2 relative">
              <input
                type="file"
                className="file-input file-input-bordered w-[19rem] file-input-primary file-input-sm bg-portal-gray"
                required
                onChange={(e) => {
                  const file =
                    e.target.files && e.target.files[0] && e.target.files[0];
                  handleDocInputChange(index, "fileUpload", file);
                }}
              />
              <CLOSECIRCLE
                className="w-7 h-7 cursor-pointer absolute -right-8 bg-portal-gray"
                onClick={() => removeDocument(index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div
        className="flex pl-4 text-sm items-center gap-3 cursor-pointer justify-center mt-2"
        onClick={() => {
          addDocument();
          setdocumentcount(documentcount + 1);
        }}
      >
        <ADDCIRCLE />
        <p>{documentcount > 0 && "Weiteres"} Dokument hinzufügen</p>
      </div>
    </>
  );
}
