import BaseToggle from "../../../base/BaseToggle";
import BaseViolationForm from "../../../base/BaseViolationForm";
import { AlcoholViolationType } from "./AlcoholViolation";

export default function AlcoholViolationScreen1({
  measuredvalue,
  vehicle,
  crash,
  necessary,
  valueReq,
  setmeasuredvalue,
  setvehicle,
  setcrash,
  setnecessary,
  setvalueReq,
}: AlcoholViolationType) {
  return (
    <BaseViolationForm>
      <BaseToggle
        toggle="placeholder!!!"
        checked={measuredvalue}
        value1="Atemalkohol"
        value2="Blutalkohol"
        // onChange={setmeasuredvalue}
        group="messearedvaluesssssssss"
        title={"Gemessener Wert"}
        label1={"Atemalkohol"}
        label2={"Blutalkohol"}
      />
      <p className="pt-8">Wertangabe</p>
      <select
        value={valueReq === "" ? undefined : valueReq}
        onChange={(e) => setvalueReq(e.target.value)}
        className="select select-bordered w-full bg-gray-100 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Wert 0,25 - 0,4"}>0,25 - 0,4</option>
        <option value={"Wert 0,4 - 0,55"}>0,4 - 0,55</option>
        <option value={"Wert mehr als 0,55"}>mehr als 0,55</option>
      </select>
      <BaseToggle
        value1={"Unfall: Ja"}
        value2={"Unfall: Nein"}
        toggle="placeholder!!!"
        // onChange={setcrash}
        checked={crash}
        group="crashssssssss"
        title="Kam es zu einem Unfall?"
        label1="Ja"
        label2="Nein"
      />
      <BaseToggle
        checked={vehicle}
        value1="Führerschein beschlagnahmt?: Ja"
        value2="Führerschein beschlagnahmt?: Nein"
        toggle="placeholder!!!"
        // onChange={setvehicle}
        group="catchdriverslicensessssssss"
        title={"Wurde der Führerschein beschlagnahmt?"}
        label1={"Ja"}
        label2={"Nein"}
      />
      <BaseToggle
        checked={necessary}
        value1="vorläufige Entziehung der Fahrerlaubnis?: Ja"
        value2="vorläufige Entziehung der Fahrerlaubnis?: Nein"
        toggle="placeholder!!!"
        // onChange={setnecessary}
        group="maystilldrivesssssssss"
        title={"Beschluss über vorläufige Entziehung der Fahrerlaubnis?"}
        label1={"Ja"}
        label2={"Nein"}
      />
    </BaseViolationForm>
  );
}
