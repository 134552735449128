import { useContext } from "react";
import { AddUserContext } from "../context/AddUserContext/AddUserContext";

type BaseInputFieldType = {
  label: string;
  type: string;
  placeholder: string;
  value?: string;
  bg?: string;
  width?: string;
  bigText?: boolean;
  stage?: 1 | 2 | 3; // NOTE -> später required, wenn Lösung für PlanMeetingModal
  attribut?: string; // NOTE -> später required, wenn Lösung für PlanMeetingModal
  setvalue?: (e: any) => void; // NOTE -> wird im addCase-Flow nicht gebraucht, nur im PlanMeetingModal -> aktuell noch keine Lösung
};
export default function BaseInputField(props: BaseInputFieldType) {
  const addCaseContext = useContext(AddUserContext);
  return (
    <label className="form-control">
      <div className={props.bigText ? "pt-4" : "pl-2 pb-1"}>
        <span className={`${props.bigText ?? "label-text"}`}>
          {props.label}
        </span>
      </div>
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={`input input-bordered border-2 input-primary ${
          props.width ? props.width : "w-48"
        } ${props.bg}`}
        value={props.value}
        onChange={
          (e) =>
            addCaseContext?.changeData(
              props.stage ?? 1,
              props.attribut ?? "",
              e.target.value
            ) // TODO wenn props.attribut required -> ?? "" wegmachen
        }
      />
    </label>
  );
}
