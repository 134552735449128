import { useEffect, useState } from 'react'
import PaceViolation from './cases/pace_violation/PaceViolation'
import RedlightViolation from './cases/redlight_violation/RedlightViolation'
import DistanceViolation from './cases/distance_violation/DistanceViolation'
import AlcoholViolation from './cases/alcohol_violation/AlcoholViolation'
import SmartphoneViolation from './cases/smartphone_violation/SmartphoneViolation'
import DifferentViolation from './cases/different_violation/DifferentViolation'
import { useQueryClient } from '@tanstack/react-query'
import { useAddCaseMutation } from '../../api/services'
import { caseType } from '../../types/Types'
import dayjs from 'dayjs'
import RaceViolation from './cases/race_violation/RaceViolation'
import ParkingViolation from './cases/parking_violation/ParkingViolation'

type AddCaseViewType = {
    setaddCaseView: () => void
}

export default function AddCaseView({ setaddCaseView }: AddCaseViewType) {
    const [screen, setscreen] = useState('Rotlichtverstoß')
    const [toggle1, settoggle1] = useState<string>('')
    const [toggle2, settoggle2] = useState<string>('')
    const [toggle3, settoggle3] = useState<string>('')
    const [toggle4, settoggle4] = useState<string>('')
    const [dd1, setdd1] = useState<string>('')
    const [dd2, setdd2] = useState<string>('')
    const [doc1, setdoc1] = useState<string>('')
    const [doc2, setdoc2] = useState<string>('')
    const [doc3, setdoc3] = useState<string>('')
    const [doc4, setdoc4] = useState<string>('')
    const [doc5, setdoc5] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [note, setnote] = useState<string>('')
    const [date, setdate] = useState<string>('')

    const [checkbox1, setcheckbox1] = useState<boolean>(false)
    const [checkbox2, setcheckbox2] = useState<boolean>(false)

    const clearToggles = () => {
        settoggle1('')
        settoggle2('')
        settoggle3('')
        settoggle4('')
        setnote('')
        setcheckbox1(false)
        setcheckbox2(false)
    }

    const queryClient = useQueryClient()
    const { mutate: addCase } = useAddCaseMutation(queryClient)
    const [documents, setdocuments] = useState([{ fileUpload: File }])
    const handleButtonClick = () => {
        let formDate = ''
        if (date !== '') {
            formDate = dayjs(date).format('DD.MM.YYYY')
        }
        const newCase: caseType = {
            law: 'Verkehrsrecht',
            title: title,
            created_at: dayjs().toString(),
            updated_at: dayjs().toString(),
            deadline: 'keine Angabe',
            status: 'new',
            note: note,
            info: [
                toggle1,
                toggle2,
                toggle3,
                toggle4,
                dd1,
                dd2,
                doc1,
                doc2,
                doc3,
                doc4,
                doc5,
                formDate,
            ].filter((obj) => obj !== ''),
        }
        const formData = new FormData()
        documents.forEach((doc) => {
            if (doc && doc.fileUpload instanceof File) {
                formData.append('documents', doc.fileUpload)
            }
        })
        formData.append('newCase', JSON.stringify(newCase))
        addCase({ formData: formData })
        setaddCaseView()
    }
    useEffect(() => {
        settitle(screen)
        clearToggles()
    }, [screen])

    return (
        <div className="w-full h-full bg-portal-gray rounded-none flex flex-row relative items-center p-4 gap-4">
            <div className="h-full w-2/5 flex flex-col gap-3 justify-center pl-16">
                <CaseBox
                    func={() => setscreen('Rotlichtverstoß')}
                    screen={screen === 'Rotlichtverstoß'}
                    title="Rotlichtverstoß"
                />
                <CaseBox
                    func={() => setscreen('Handyverstoß')}
                    screen={screen === 'Handyverstoß'}
                    title="Handyverstoß"
                />
                <CaseBox
                    func={() => setscreen('Abstandsverstoß')}
                    screen={screen === 'Abstandsverstoß'}
                    title="Abstandsverstoß"
                />
                <CaseBox
                    func={() => setscreen('Illegale Autorennen')}
                    screen={screen === 'Illegale Autorennen'}
                    title="Illegale Autorennen"
                />
                <CaseBox
                    func={() => setscreen('Geschwindigkeitsverstoß')}
                    screen={screen === 'Geschwindigkeitsverstoß'}
                    title="Geschwindigkeitsverstoß"
                />
                <CaseBox
                    func={() => setscreen('Park- & Abschleppmaßnahmen')}
                    screen={screen === 'Park- & Abschleppmaßnahmen'}
                    title="Park- & Abschleppmaßnahmen"
                />
                <CaseBox
                    func={() => setscreen('Alkoholdelikte')}
                    screen={screen === 'Alkoholdelikte'}
                    title="Alkoholdelikte"
                />
                <CaseBox
                    func={() => setscreen('Anderer Verstoß')}
                    screen={screen === 'Anderer Verstoß'}
                    title="Anderer Verstoß"
                />
            </div>
            {screen === 'Geschwindigkeitsverstoß' ? (
                <PaceViolation
                    location={toggle1}
                    vehicle={toggle2}
                    timeline={toggle3}
                    necessary={toggle4}
                    pace={dd1}
                    note={note}
                    check1={checkbox1}
                    check2={checkbox2}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    setnote={setnote}
                    setlocation={settoggle1}
                    setvehicle={settoggle2}
                    settimeline={settoggle3}
                    setnecessary={settoggle4}
                    setpace={setdd1}
                    handleSubmit={handleButtonClick}
                    documents={documents}
                    setdocuments={setdocuments || (() => {})}
                />
            ) : screen === 'Rotlichtverstoß' ? (
                <RedlightViolation
                    redlighttime={toggle1}
                    crash={toggle2}
                    personindanger={toggle3}
                    necessary={toggle4}
                    note={note}
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    setnote={setnote}
                    setredlighttime={settoggle1}
                    setcrash={settoggle2}
                    setpersonindanger={settoggle3}
                    setnecessary={settoggle4}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    handleSubmit={handleButtonClick}
                    documents={documents}
                    setdocuments={setdocuments}
                />
            ) : screen === 'Abstandsverstoß' ? (
                <DistanceViolation
                    distance={dd1}
                    setdistance={setdd1}
                    pace={dd2}
                    setpace={setdd2}
                    note={note}
                    setnote={setnote}
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    addButtonClick={handleButtonClick}
                    documents={documents}
                    setdocuments={setdocuments}
                />
            ) : screen === 'Alkoholdelikte' ? (
                <AlcoholViolation
                    measuredvalue={toggle1}
                    vehicle={toggle2}
                    crash={toggle3}
                    necessary={toggle4}
                    valueReq={dd1}
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    setvalueReq={setdd1}
                    setmeasuredvalue={settoggle1}
                    setvehicle={settoggle2}
                    setcrash={settoggle3}
                    setnecessary={settoggle4}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    setdoc4={setdoc4}
                    setdoc5={setdoc5}
                    addCaseClick={handleButtonClick}
                    documents={documents}
                    setdocuments={setdocuments}
                />
            ) : screen === 'Handyverstoß' ? (
                <SmartphoneViolation
                    vehicle={toggle1}
                    crash={toggle2}
                    necessary={toggle3}
                    note={note}
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    setnote={setnote}
                    setvehicle={settoggle1}
                    setcrash={settoggle2}
                    setnecessary={settoggle3}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    addCaseClick={handleButtonClick}
                    documents={documents}
                    setdocuments={setdocuments}
                />
            ) : screen === 'Illegale Autorennen' ? (
                <RaceViolation
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    note={note}
                    setnote={setnote}
                    handleSubmit={handleButtonClick}
                    crash={toggle1}
                    personindanger={toggle2}
                    necessary={toggle3}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    setcrash={settoggle1}
                    setpersonindanger={settoggle2}
                    setnecessary={settoggle3}
                    documents={documents}
                    setdocuments={setdocuments}
                />
            ) : screen === 'Park- & Abschleppmaßnahmen' ? (
                <ParkingViolation
                    check1={checkbox1}
                    check2={checkbox2}
                    date={date}
                    setdate={setdate}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    note={note}
                    setnote={setnote}
                    setdoc1={setdoc1}
                    setdoc2={setdoc2}
                    setdoc3={setdoc3}
                    documents={documents}
                    setdocuments={setdocuments}
                    handleSubmit={handleButtonClick}
                />
            ) : screen === 'Anderer Verstoß' ? (
                <DifferentViolation
                    check1={checkbox1}
                    check2={checkbox2}
                    setcheck1={setcheckbox1}
                    setcheck2={setcheckbox2}
                    note={note}
                    setnote={setnote}
                    addCaseClick={handleButtonClick}
                />
            ) : (
                <p>Not Found</p>
            )}
        </div>
    )
}

type CaseBoxType = {
    title: string
    screen: boolean
    func: () => void
}

function CaseBox({ title, func, screen }: CaseBoxType) {
    return (
        <div
            onClick={func}
            className={`rounded-lg ${
                screen ? 'border-accent' : 'border-primary'
            } border-2 p-4 hover:border-accent cursor-pointer`}>
            <p className="font-semibold text-sm">{title}</p>
        </div>
    )
}
