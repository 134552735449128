import BaseToggle from "../../../base/BaseToggle";
import BaseViolationForm from "../../../base/BaseViolationForm";
import { RedlightViolationType } from "./RedlightViolation";

export default function RedlightViolationScreen1({
  redlighttime,
  crash,
  personindanger,
  necessary,
  setredlighttime,
  setcrash,
  setpersonindanger,
  setnecessary,
}: RedlightViolationType) {
  return (
    <BaseViolationForm>
      <BaseToggle
        value1={"Ampel weniger als 1sek rot"}
        value2={"Ampel mehr als 1sek rot"}
        checked={redlighttime}
        toggle="placeholder!!!"
        // onChange={setredlighttime}
        group="redlighttime"
        title="Wie lang war die Ampel bereits rot?"
        label1="weniger als 1 Sekunde"
        label2="mehr als 1 Sekunde"
      />
      <BaseToggle
        value1={"Unfall: Ja"}
        value2={"Unfall: Nein"}
        toggle="placeholder!!!"
        // onChange={setcrash}
        checked={crash}
        group="crash"
        title="Kam es zu einem Unfall?"
        label1="Ja"
        label2="Nein"
      />
      <BaseToggle
        value1={"Person gefährdet: Ja"}
        value2={"Person gefährdet: Nein"}
        checked={personindanger}
        toggle="placeholder!!!"
        // onChange={setpersonindanger}
        group="personindanger"
        title="Wurde eine Person gefährdet?"
        label1="Ja"
        label2="Nein"
      />
      <BaseToggle
        value1={"Beruflich Angewiesen: Ja"}
        value2={"Beruflich Angewiesen: Nein"}
        checked={necessary}
        toggle="placeholder!!!"
        // onChange={setnecessary}
        group="necessary"
        title="Sind Sie beruflich auf den Führerschein angewiesen?"
        label1="Ja"
        label2="Nein"
      />
    </BaseViolationForm>
  );
}
