import BaseDataCheck from "../../../base/BaseDataCheck";
import BaseDocsSelection from "../../../base/BaseDocsSelection";
import BaseTextAreaSpecialties from "../../../base/BaseTextAreaSpecialties";
import BaseViolationForm from "../../../base/BaseViolationForm";
import { ParkingViolationType } from "./ParkingViolation";

export default function ParkingViolationScreen1({
  note,
  setnote,
  check1,
  check2,
  date,
  setcheck1,
  setcheck2,
  setdate,
  extend,
  setdoc1,
  setdoc2,
  setdoc3,

  documents,
  setdocuments,
}: ParkingViolationType) {
  return (
    <BaseViolationForm>
      <div className="overflow-y-scroll">
        <BaseTextAreaSpecialties title={"Kurze Erläuterung"} value={note} />

        <BaseDataCheck
          check1={check1}
          setcheck1={setcheck1}
          check2={check2}
          setcheck2={setcheck2}
        />
      </div>
    </BaseViolationForm>
  );
}
