import { useEffect, useRef, useState } from "react";
import BasePanelHeadline from "./BasePanelHeadline";
import { useAddMessageMutation } from "../../api/services";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ReactComponent as SendICON } from "../../assets/PaperSend.svg";

type BasePanelChatType = {
  uid: string;
  cid: string;
  sender?: string;
  status: boolean;
  chat?: {
    sender: string;
    timestamp: string;
    message: string;
  }[]; // TODO required
};

export default function BasePanelChat({
  uid,
  cid,
  sender,
  status,
  chat,
}: BasePanelChatType) {
  // Basic Code to scroll Chat on its last position
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      const height = chatContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      chatContainerRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [chat]);

  const [message, setmessage] = useState<string>("");
  const queryClient = useQueryClient();
  const { mutate: sendMessage } = useAddMessageMutation(queryClient);
  const handleSend = () => {
    if (!status) {
      sendMessage({
        uid: uid,
        cid: cid,
        message: {
          sender: sender ?? "RCLNT", // TODO add user which is logged in -> beachte Model!
          timestamp: dayjs().toString(),
          message: message,
        },
      });
      setmessage("");
    }
  };

  return (
    <div className="flex flex-col gap-2 rounded-default bg-base-300 w-1/3 max-w-[22rem] h-full p-4 relative">
      <BasePanelHeadline title={"Chat"} />
      <div className="grow" />
      <div
        className="flex flex-col overflow-y-scroll h-fit"
        ref={chatContainerRef}
      >
        {chat?.map((item, index) => (
          <MessageBlock
            key={index}
            sender={item.sender === sender}
            content={item.message}
            timestamp={item.timestamp}
          />
        ))}
      </div>
      <div
        className={`${
          !status ? "hidden" : "flex"
        } justify-center items-center gap-2`}
      >
        <p className="uppercase text-gray-300 font-semibold text-sm">
          Der Fall ist aktuell nicht geöffnet
        </p>
      </div>
      <div
        className={`${
          status ? "hidden" : "flex"
        } justify-between items-end gap-2`}
      >
        <textarea
          className="textarea h-24 border-primary bg-base-300 w-full"
          value={message}
          onChange={(e) => setmessage(e.target.value)}
          style={{ backgroundColor: "rgb(245, 245, 245)" }}
        />
        <SendICON
          onClick={handleSend}
          className="w-7 h-7 text-primary cursor-pointer"
        />
      </div>
    </div>
  );
}

type MessageBlockType = {
  sender: boolean;
  content: string;
  timestamp: string;
};

function MessageBlock({ sender, content, timestamp }: MessageBlockType) {
  return (
    <div className={`chat ${!sender ? "chat-start" : "chat-end"}`}>
      <div
        className={`flex flex-col pt-1 ${sender && "justify-end items-end"}`}
      >
        <label className="text-xs font-bold">
          {dayjs(timestamp).format("DD.MM.YYYY HH:mm")} Uhr
        </label>
        <div className="rounded-lg bg-primary/20 p-3 text-sm leading-4 max-w-[16rem]">
          {content}
        </div>
      </div>
    </div>
  );
}
