import { useMemo, useState } from "react";
import BaseModal from "../../layout/BaseModal";
import { useQueryClient } from "@tanstack/react-query";
import { useAddDocToCaseMutation } from "../../../api/services";
import LoadingUpload from "../../common/LoadingUpload";

type AddDocModalType = {
  uid: string;
  cid: string;
  mid: number;
  modalCon: any;
};

export default function AddDocModal(props: AddDocModalType) {
  const [documentcount, setdocumentcount] = useState<number>(1);

  const queryClient = useQueryClient();
  const { mutate: addDoc, status } = useAddDocToCaseMutation(
    queryClient,
    props.modalCon,
    1,
    true
  );

  // init // set documents of no value & one doc to upload
  const clearInputData = () => {
    setdocumentcount(1);
    setdocuments([{ title: "", fileUpload: File, uploadedByUser: true }]);
  };
  const [documents, setdocuments] = useState([
    { title: "", fileUpload: File, uploadedByUser: true },
  ]); // TODO no meta data for doc needed -> only file array to form data field 'documents'

  // add doc to doc-array-useState
  const addDocument = () => {
    setdocuments((prevDocuments) => [
      ...prevDocuments,
      { title: "", fileUpload: File, uploadedByUser: true },
    ]);
  };

  // remove Doc from useState-array
  const removeDocument = (indexToRemove: number) => {
    setdocuments((prevValues) =>
      prevValues.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleInputChange = (
    index: number,
    key: string,
    newValue?: string | File | null
  ) => {
    setdocuments((prevDocuments) =>
      prevDocuments.map((item, idx) =>
        idx === index ? { ...item, [key]: newValue } : item
      )
    );
  };
  const handleFileUpload = () => {
    const formData = new FormData();
    documents.forEach((doc) => {
      if (doc && doc.fileUpload instanceof File) {
        formData.append("documents", doc.fileUpload);
      }
    });
    addDoc({ uid: props.uid, cid: props.cid, data: formData });
    clearInputData();
  };

  const noInputButAdded = useMemo<boolean>(() => {
    if (documents.every((item) => item.fileUpload.name !== "File")) {
      return false;
    } else {
      return true;
    }
  }, [documents]);

  return (
    <>
      {status === "pending" ? (
        <LoadingUpload />
      ) : (
        <BaseModal
          size
          hiddenCross
          modalId={props.mid}
          title={"Dokument hinzufügen"}
          bottomRow={
            <div className="flex justify-end w-full">
              <button
                disabled={noInputButAdded}
                className="btn btn-secondary"
                onClick={handleFileUpload}
              >
                Speichern
              </button>
            </div>
          }
        >
          <div className="px-2">
            {documents.map((item, index) => (
              <div key={index} className="flex flex-row gap-2 items-end pt-6">
                <div className="flex mt-2 items-center gap-2 relative">
                  <input
                    type="file"
                    className="file-input file-input-bordered w-80 file-input-primary"
                    required
                    onChange={(e) => {
                      const file =
                        e.target.files &&
                        e.target.files[0] &&
                        e.target.files[0];
                      handleInputChange(index, "fileUpload", file);
                    }}
                  />
                  {index !== 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-7 h-7 cursor-pointer absolute -top-7 right-0"
                      onClick={() => removeDocument(index)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div
            className="flex pl-4 text-sm items-center gap-3 cursor-pointer justify-center pt-4 pb-2"
            onClick={() => {
              addDocument();
              setdocumentcount(documentcount + 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p>Weiteres Dokument hinzufügen</p>
          </div>
        </BaseModal>
      )}
    </>
  );
}
