import { ReactNode } from "react";

type BasePanelLayoutType = {
  col?: boolean;
  children: ReactNode;
};

export default function BasePanelLayout({
  children,
  col,
}: BasePanelLayoutType) {
  return (
    <div
      className={`grow h-full w-max rounded-none flex gap-default ${
        col ? "flex-col" : "flex-row"
      }`}
    >
      {children}
    </div>
  );
}
