import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <p className="underline font-bold">Huh? Error!</p>
      <Link to={"/registrierung"}>Zurück</Link>
    </div>
  );
}
