import BaseViolationForm from "../../../base/BaseViolationForm";
import { DistanceViolationType } from "./DistanceViolation";

export default function DistanceViolationScreen1({
  distance,
  setdistance,
  pace,
  setpace,
}: DistanceViolationType) {
  return (
    <BaseViolationForm>
      <p className="pt-8">Unterschreitung des Abstands</p>
      <select
        value={distance === "" ? undefined : distance}
        onChange={(e) => setdistance(e.target.value)}
        className="select select-bordered w-full bg-gray-100 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Abstandsunterschreitung: 5 / 10"}>5 / 10</option>
        <option value={"Abstandsunterschreitung: 4 / 10"}>4 / 10</option>
        <option value={"Abstandsunterschreitung: 3 / 10"}>3 / 10</option>
        <option value={"Abstandsunterschreitung: 2 / 10"}>2 / 10</option>
        <option value={"Abstandsunterschreitung: 1 / 10"}>1 / 10</option>
      </select>

      <p className="pt-8">Gefahrene Geschwindigkeit</p>
      <select
        value={pace === "" ? undefined : pace}
        onChange={(e) => setpace(e.target.value)}
        className="select select-bordered w-full bg-gray-100 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Gefahrene Geschwindigkeit: bis 80 km/h"}>
          bis 80 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: 81 - 100 km/h"}>
          81 - 100 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: 101 - 130 km/h"}>
          101 - 130 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: über 130 km/h"}>
          über 130 km/h
        </option>
      </select>
    </BaseViolationForm>
  );
}
