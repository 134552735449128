import { useState } from "react";
import BaseCaseForm from "../../../base/BaseCaseForm";
import FormScreen2 from "../FormScreen2";
import DifferentViolationScreen1 from "./SmartphoneViolationScreen1";

export type SmartphoneViolationType = {
  vehicle: string;
  crash: string;
  necessary: string;
  note?: string;
  check1?: boolean;
  setcheck1?: (e: boolean) => void;
  check2?: boolean;
  setcheck2?: (e: boolean) => void;
  setnote?: (e: string) => void;
  setvehicle: (e: string) => void;
  setcrash: (e: string) => void;
  setnecessary: (e: string) => void;
  addCaseClick?: () => void;
  setdoc1?: (e: string) => void;
  setdoc2?: (e: string) => void;
  setdoc3?: (e: string) => void;
  documents?: any;
  setdocuments?: (e: any) => void;
};

export default function SmartphoneViolation({
  vehicle,
  crash,
  necessary,
  note,
  check1,
  check2,
  documents,
  setdocuments,
  setdoc1,
  setdoc2,
  setdoc3,
  setcheck1,
  setcheck2,
  setnote,
  setvehicle,
  setcrash,
  setnecessary,
  addCaseClick,
}: SmartphoneViolationType) {
  const [screen, setscreen] = useState(1);

  return (
    <BaseCaseForm
      buttonRow={
        <div className="pl-12 w-full">
          {screen === 1 ? (
            <button
              className="btn bg-black/20 hover:bg-black/30 hover:border-none w-full text-base-100"
              onClick={() => setscreen(2)}
              disabled={vehicle === "" || necessary === "" || crash === ""}
            >
              Nächste Seite
            </button>
          ) : (
            <div className="flex gap-2 w-full">
              <button className="btn btn-ghost" onClick={() => setscreen(1)}>
                zurück
              </button>
              <button
                onClick={addCaseClick}
                className="btn bg-accent grow text-base-100 hover:bg-accent/90"
                disabled={!check1 || !check2}
              >
                Fall melden
              </button>
            </div>
          )}
        </div>
      }
    >
      {screen === 1 ? (
        <DifferentViolationScreen1
          vehicle={vehicle}
          crash={crash}
          necessary={necessary}
          setvehicle={setvehicle}
          setcrash={setcrash}
          setnecessary={setnecessary}
        />
      ) : (
        <FormScreen2
          documents={documents}
          setdocuments={setdocuments || (() => {})}
          note={note ?? ""}
          setnote={setnote ?? (() => {})}
          check1={check1 ?? false}
          check2={check2 ?? false}
          setcheck1={setcheck1 ?? (() => {})}
          setcheck2={setcheck2 ?? (() => {})}
          setdoc1={setdoc1 ?? (() => {})}
          setdoc2={setdoc2 ?? (() => {})}
          setdoc3={setdoc3 ?? (() => {})}
        />
      )}
    </BaseCaseForm>
  );
}
