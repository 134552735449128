import { useContext, useEffect } from "react";
import BaseViolationForm from "../../../../../components/base/BaseViolationForm";
import { AddUserContext } from "../../../../../components/context/AddUserContext/AddUserContext";
import BaseDocsSelection from "../../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../../Types";
import BaseInputField from "../../../../../components/base/BaseInputField";
import BaseTextAreaSpecialties from "../../../../../components/base/BaseTextAreaSpecialties";
import { checkForValue } from "../../../../../services/functions";

export default function DistanceViolation(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);

  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", "Abstandsverstoß");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      checkForValue(addCaseContext?.caseData?.dd1) &&
      checkForValue(addCaseContext?.caseData?.dd2)
    ) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <p className="pt-4">Unterschreitung des Abstands *</p>
      <select
        value={addCaseContext?.caseData?.dd1}
        onChange={(e) => addCaseContext?.changeData(2, "dd1", e.target.value)}
        className="select select-bordered w-full bg-gray-100 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Abstandsunterschreitung: 5 / 10"}>5 / 10</option>
        <option value={"Abstandsunterschreitung: 4 / 10"}>4 / 10</option>
        <option value={"Abstandsunterschreitung: 3 / 10"}>3 / 10</option>
        <option value={"Abstandsunterschreitung: 2 / 10"}>2 / 10</option>
        <option value={"Abstandsunterschreitung: 1 / 10"}>1 / 10</option>
      </select>
      <p className="pt-4">Gefahrene Geschwindigkeit *</p>
      <select
        value={addCaseContext?.caseData?.dd2}
        onChange={(e) => addCaseContext?.changeData(2, "dd2", e.target.value)}
        className="select select-bordered w-full bg-gray-100 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Gefahrene Geschwindigkeit: bis 80 km/h"}>
          bis 80 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: 81 - 100 km/h"}>
          81 - 100 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: 101 - 130 km/h"}>
          101 - 130 km/h
        </option>
        <option value={"Gefahrene Geschwindigkeit: über 130 km/h"}>
          über 130 km/h
        </option>
      </select>
      <BaseTextAreaSpecialties
        title={"Bemerkung "}
        heightProps="max-h-32 h-full"
        paddingTop="pt-4"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={"Frist des Falls"}
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection extend={false} />
    </BaseViolationForm>
  );
}
