import { ReactComponent as QuestionMarkICON } from "./../../assets/QuestionMark.svg";

type BasePanelHeadlineType = {
  title: string;
  classProps?: string;

  tooltip?: {
    badge: boolean;
    func: () => void;
  };
};

export default function BasePanelHeadline(props: BasePanelHeadlineType) {
  return (
    <div
      className={`w-full flex flex-col items-center justify-center ${props.classProps}`}
    >
      <h3 className="text-2xl font-normal uppercase relative">
        {props.title}
        {props.tooltip?.badge && (
          <QuestionMarkICON
            className="w-5 h-5 absolute top-0 -right-8 cursor-pointer"
            onClick={
              props.tooltip?.badge ? () => props.tooltip?.func() : undefined
            }
          />
        )}
      </h3>
      <div className="border-t-2 border-primary w-12 mt-1" />
    </div>
  );
}
