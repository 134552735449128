import { motion } from "framer-motion";
import { ReactNode, useState } from "react";

type BaseActionButtonType = {
  svg: ReactNode;
  color: string;
  explanation: string;
  function: (e?: string | number | boolean) => void;
};

export default function BaseActionButton(props: BaseActionButtonType) {
  const [hover, sethover] = useState(false);
  return (
    <motion.button
      className={`h-fit rounded-full flex justify-center overflow-hidden items-center w-fit p-3 ${props.color}`}
      onClick={() => props.function()}
      onHoverEnd={() => sethover(false)}
      onHoverStart={() => sethover(true)}
    >
      {props.svg}
      <motion.p
        initial={{
          x: "-100%",
          width: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
        animate={{
          x: hover ? 0 : "-100%",
          width: hover ? "fit-content" : 0,
          paddingLeft: hover ? "0.5rem" : 0,
          paddingRight: hover ? "0.5rem" : 0,
        }}
        transition={{
          type: "ease",
          duration: 0.2,
        }}
        className="truncate"
      >
        {props.explanation}
      </motion.p>
    </motion.button>
  );
}
