import { HashLink } from "react-router-hash-link";
import { ReactComponent as SAD } from "./../../../assets/SadEmoji.svg";
import { motion } from "framer-motion";
import { ScreenType } from "../Types";

export function ErrorScreen(props: ScreenType) {
  return (
    <motion.div
      initial={{ x: props.screen < 5 ? "120%" : 0 }}
      animate={{
        x: props.screen === 5 ? 0 : props.screen > 3 ? "-120%" : undefined,
      }}
      transition={{
        type: "ease",
      }}
      className="bg-base-200 h-full w-full absolute flex flex-col p-4 rounded-default items-center justify-center"
    >
      <motion.div
        initial={{
          rotate: props.screen === 3 ? 0 : 0,
          scale: props.screen === 3 ? 0 : 0,
        }}
        animate={{
          rotate: props.screen === 3 ? 360 : undefined,
          scale: props.screen === 3 ? 3 : 1,
        }}
        transition={{
          delay: 1,
          loop: Infinity,
          transition: "ease",
        }}
      >
        <SAD />
      </motion.div>
      <p className="text-xl font-bold uppercase pt-8">
        Ups! Das hat nicht geklappt!
      </p>
      <p className="text-base font-semibold">
        Ein unerwarteter Fehler ist aufgetreten.
      </p>
      <p className="max-w-lg text-xs text-center">
        Bitte überprüfen Sie Ihre eingegebenen Daten. Mögliche Fehlerquellen
        können beschädigte Dateien oder bereits verwendete E-Mail-Adressen sein.
      </p>
      <HashLink to="/" className="uppercase pt-2 underline">
        Sie haben bereits einen Account? Direkt einloggen!
      </HashLink>
    </motion.div>
  );
}
