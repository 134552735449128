import { useContext } from "react";
import { AddUserContext } from "../context/AddUserContext/AddUserContext";

type BaseToggleType = {
  title: string;
  label1: string;
  label2: string;
  group: string;
  checked?: string;
  value1: string;
  value2: string;
  toggle: string;
};

export default function BaseToggle({
  title,
  label1,
  label2,
  group,
  checked,
  value1,
  value2,
  toggle,
}: BaseToggleType) {
  const addCaseContext = useContext(AddUserContext);
  return (
    <>
      <p className="pt-8">{title}</p>
      <div className="flex justify-evenly w-full pt-2">
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name={group}
            className="radio radio-primary border-2"
            checked={checked === value1}
            onChange={() => addCaseContext?.changeData(2, toggle, value1)}
          />
          <label>{label1}</label>
        </div>
        <div className="flex gap-3 items-center">
          <input
            type="radio"
            name={group}
            className="radio radio-primary border-2"
            checked={checked === value2}
            onChange={() => addCaseContext?.changeData(2, toggle, value2)}
          />
          <label>{label2}</label>
        </div>
      </div>
    </>
  );
}
