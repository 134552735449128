import { GroupedEvents, MSCalendarEventType } from "../types/MSTypes";
import { userType } from "../types/Types";

export const operateWithCallOption = (callOption: number) => {
  switch (callOption) {
    case 1:
      return "Morgens, 08:00 Uhr bis 11:00 Uhr";
    case 2:
      return "Vormittags, 11:00 Uhr bis 14:00 Uhr";
    case 3:
      return "Nachmittags, 14:00 Uhr bis 17:00 Uhr";
    case 4:
      return "Abends, 17:00 Uhr bis 20:00 Uhr";
  }
};
export function getSuffix(fileName: string) {
  const dotIndex = fileName.lastIndexOf(".");

  if (dotIndex === -1 || dotIndex === fileName.length - 1) {
    return "";
  }

  return fileName.substring(dotIndex + 1);
}

export const groupEventsByDate = (
  events: MSCalendarEventType[]
): GroupedEvents => {
  const now = new Date(new Date());
  now.setUTCHours(0, 0, 0, 0);

  const groupedEvents: GroupedEvents = {};

  events.forEach((event) => {
    const eventStartUTC = new Date(event.start.dateTime + "Z");
    const startDate = new Date(
      eventStartUTC.getTime() + eventStartUTC.getTimezoneOffset() * 60000
    );
    startDate.setUTCHours(0, 0, 0, 0);
    const dayDiff =
      (startDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24);

    let key: string;
    if (dayDiff === 0) {
      key = "Heute";
    } else if (dayDiff === 1) {
      key = "Morgen";
    } else {
      key = startDate.toLocaleDateString("de-DE", {
        weekday: "short",
        day: "numeric",
        month: "numeric",
      });
    }

    if (!groupedEvents[key]) {
      groupedEvents[key] = [];
    }

    groupedEvents[key].push(event);
  });
  const keyToDate = (key: string, today: Date, tomorrow: Date) => {
    if (key === "Heute") return today;
    if (key === "Morgen") return tomorrow;

    const [day, month] = key.split(", ")[1].split(".").map(Number);
    const year = today.getFullYear();
    return new Date(year, month - 1, day);
  };

  const sortGroupedEvents = (groupedEvents: GroupedEvents): GroupedEvents => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const sortedEntries = Object.entries(groupedEvents).sort(
      ([keyA], [keyB]) => {
        const dateA = keyToDate(keyA, today, tomorrow);
        const dateB = keyToDate(keyB, today, tomorrow);
        return dateA.getTime() - dateB.getTime();
      }
    );

    const sortedGroupedEvents: GroupedEvents = {};
    sortedEntries.forEach(([key, events]) => {
      sortedGroupedEvents[key] = events;
    });

    return sortedGroupedEvents;
  };

  return sortGroupedEvents(groupedEvents);
};

export const extractNameFromEvent = (
  event: MSCalendarEventType
): MSCalendarEventType => {
  const regex = /<<(.*?):(.*?)>>/;
  const match =
    event.body.contentType.toLowerCase() === "html"
      ? new DOMParser()
          .parseFromString(event.body.content, "text/html")
          .body.textContent?.match(regex)
      : event.bodyPreview.match(regex);

  if (match && match[2]) {
    const decodedString = atob(match[2]);
    event.displayName = decodedString;
  } else {
    event.displayName = event.subject;
  }
  return event;
};

export const extractAndFormatEventTime = (event: MSCalendarEventType) =>
  new Date(event.start.dateTime + "Z").toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Berlin",
  });

export const generateBodyFromCustomer = (customer: userType) => {
  return `Mandant: \t${customer.personal.first_name} ${customer.personal.last_name}\nTelefon: \t${customer.personal.tel}\nE-Mail: \t${customer.personal.mail}`;
};

// checks if value is not an empty string or undefined
export const checkForValue = (value: any) => {
  return value !== "" && value !== undefined && value !== 0;
};
