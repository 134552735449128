import { Link } from "react-router-dom";
import { newsDataType } from "../../types/Types";
import BasePanelHeadline from "../base/BasePanelHeadline";
import { useDeleteNewMutation } from "../../api/services";
import { useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

type NewsListType = {
  news: newsDataType[];
};

export default function NewsList({ news }: NewsListType) {
  const queryClient = useQueryClient();
  const { mutate: deleteNew } = useDeleteNewMutation(queryClient);
  const handleClick = (id: string) => {
    deleteNew(id);
  };

  return (
    <div className="flex flex-col py-4 px-12 h-full w-full bg-base-300 relative rounded-default">
      <BasePanelHeadline title={"Neuigkeiten"} />
      <div className="flex flex-col w-full h-72 overflow-y-scroll">
        {news.length === 0 && (
          <p className="text-center uppercase text-sm font-semibold pt-4">
            Keine Neuigkeiten
          </p>
        )}
        {news
          ?.sort((a, b) => {
            return (
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
            );
          })
          .map((item, index) => (
            <NewsElement
              key={index}
              title={item?.user}
              content={item?.topic}
              func={() => handleClick(item._id ?? "")}
            />
          ))}
      </div>
    </div>
  );
}

type NewsElementType = {
  title: {
    uid: string;
    caseid?: string;
    first_name: string;
    last_name: string;
  };
  content: string;
  func: () => void;
};

function NewsElement({ title, content, func }: NewsElementType) {
  const [showDelete, setshowDelete] = useState(false);
  useEffect(() => {
    console.log(showDelete);
  }, [showDelete]);

  return (
    <div
      className="relative w-full hover:border-y"
      onMouseEnter={() => setshowDelete(true)}
      onMouseLeave={() => setshowDelete(false)}
    >
      <Link
        onClick={func}
        className="flex flex-row relative py-2 justify-start"
        to={
          "/portal/mandanten/" +
          title.uid +
          "/" +
          (title?.caseid ? title?.caseid : "")
        }
      >
        <p className="text-3xl font-medium text-accent absolute top-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </p>
        <div className="flex flex-col pl-8">
          <p className="font-semibold text-base leading-6">
            {title.first_name} {title.last_name}
          </p>
          <p className="text-sm">{content}</p>
        </div>
      </Link>
      <motion.div
        initial={{
          width: 0,
        }}
        animate={{
          width: showDelete ? "10%" : 0,
        }}
        transition={{
          type: "ease",
          duration: 0.25,
        }}
        onClick={func}
        className="cursor-pointer h-full bg-black/10 absolute right-0 top-0 flex items-center justify-center "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 hover:text-red-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
      </motion.div>
    </div>
  );
}
