import {
    AuthenticationResult,
    IPublicClientApplication,
} from '@azure/msal-browser'
import axios from 'axios'
import {
    MSCalendarCreateEventType,
    MSCalendarEventType,
} from '../../types/MSTypes'
import { log } from 'console'
import {
    extractNameFromEvent,
    generateBodyFromCustomer,
} from '../../services/functions'
import { userType } from '../../types/Types'

const MS_CALENDAR_BASE_URL = 'https://graph.microsoft.com/v1.0/me/'
const MS_CALENDAR_CUSTOM_ID = '3297f05d-bfcf-4e6b-acf4-e4fb1cc57d8a'
const MS_CALENDAR_CUSTOM_ID_TEXT = (customerName: string) =>
    `Das ist ein automatisch generierter Termin - diesen Abschnitt bitte nicht löschen <<${MS_CALENDAR_CUSTOM_ID}:${btoa(
        customerName
    )}>>`

export const getAllEventsInTheNextWeek = async (
    tokenString?: string
): Promise<MSCalendarEventType[]> => {
    if (!tokenString) return []
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)
    const nextWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7
    )
    const qry = `startDateTime=${today.toISOString()}&endDateTime=${nextWeek.toISOString()}&$top=1000`
    const token = `Bearer ${tokenString}`
    return axios
        .get(`${MS_CALENDAR_BASE_URL}calendarView?${qry}`, {
            headers: {
                Authorization: token,
            },
        })

        .then(
            (res) =>
                res.data?.value
                    ?.filter((event: MSCalendarEventType) =>
                        event.body.content.includes(MS_CALENDAR_CUSTOM_ID)
                    )
                    .map((event: MSCalendarEventType) =>
                        extractNameFromEvent(event)
                    ) ?? []
        )
}

export const createNewEvent = async ({
    tokenString,
    customer,
    newEvent,
}: {
    tokenString?: string
    customer: userType
    newEvent: MSCalendarCreateEventType
}) => {
    if (!tokenString) return Promise.resolve()
    const token = `Bearer ${tokenString}`
    newEvent.body = {
        contentType: 'text',
        content: `${generateBodyFromCustomer(
            customer
        )}\n\n${MS_CALENDAR_CUSTOM_ID_TEXT(
            customer.personal.first_name + ' ' + customer.personal.last_name
        )}`,
    }
    return await axios.post(`${MS_CALENDAR_BASE_URL}events`, newEvent, {
        headers: {
            Authorization: token,
        },
    })
}
