import dayjs from "dayjs";
import React, { ReactNode, createContext, useState } from "react";
import { useAddUserMutation } from "../../../api/services";
import { useQueryClient } from "@tanstack/react-query";
import {
  caseType,
  personalType,
  requestCaseSchema,
} from "../../../types/Types";

export type AddUserContextProps = {
  status: string,
  caseData: requestCaseSchema | undefined;
  personalData: personalType | undefined;
  documents:
    | {
        fileUpload: File;
      }[]
    | undefined;
  setdocuments: (e: any) => void;
  clearData: () => void;
  changeData: (
    personal: 1 | 2 | 3,
    attribut: string,
    data: string | number | boolean
  ) => void;
  addData: () => void;
};

export type AddCaseContextProviderProps = {
  children: ReactNode;
};

export const AddUserContext = createContext<AddUserContextProps | undefined>(
  undefined
);

export const AddUserContextProvider: React.FC<AddCaseContextProviderProps> = ({
  children,
}: AddCaseContextProviderProps) => {
  const [documents, setdocuments] = useState<{ fileUpload: File }[]>([]);
  const [caseData, setcaseData] = useState<requestCaseSchema>();
  const [personalData, setpersonalData] = useState<personalType>();

  const changeData = (
    stageOfChange: 1 | 2 | 3,
    attribut: string,
    data: string | number | boolean
  ) => {
    if (stageOfChange === 1) {
      setpersonalData((prevData) => ({
        ...prevData!,
        [attribut]: data,
      }));
    } else if (stageOfChange === 2) {
      setcaseData((prevData) => ({
        ...prevData!,
        [attribut]: data,
      }));
    } else if (stageOfChange === 3) {
      setpersonalData((prevData) => ({
        ...prevData!,
        adress: {
          ...prevData!.adress,
          [attribut]: data,
        },
      }));
    }
  };

  const clearData = () => {
    setcaseData(undefined);
    setpersonalData(undefined);
  };

  const queryClient = useQueryClient();
  const { mutate: addNewUser, status } = useAddUserMutation(queryClient);

  // function to actually add the new user
  const addData = () => {
    const formData = new FormData();
    documents?.forEach((doc) => {
      if (doc && doc.fileUpload instanceof File) {
        formData.append("documents", doc.fileUpload);
      }
    });
    const newCase: caseType = {
      law: caseData?.law ?? "", //charAt(0).toUpperCase() + caseData!.law.slice(1),
      title: caseData!.title,
      created_at: dayjs().toString(),
      updated_at: dayjs().toString(),
      deadline: caseData?.deadline
        ? dayjs(caseData?.deadline).toString()
        : "keine Angabe",
      status: "new",
      info: Object.values(caseData!).filter(
        (item) =>
          item !== "" &&
          item !== caseData?.title &&
          item !== caseData?.law &&
          item !== caseData?.note &&
          item !== caseData?.deadline
      ),
      note: caseData?.note,
    };
    setpersonalData((prevData) => ({
      ...prevData!,
      callOption: Number(personalData?.callOption),
    }));
    formData.append("personal", JSON.stringify(personalData));
    formData.append("cases", JSON.stringify(newCase));
    addNewUser({ formData: formData });
    console.log(personalData);
    console.log(newCase);
  };

  return (
    <AddUserContext.Provider
      value={{
        status,
        addData,
        changeData,
        clearData,
        setdocuments,
        documents,
        caseData,
        personalData,
      }}
    >
      {children}
    </AddUserContext.Provider>
  );
};
