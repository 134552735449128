import { motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { RBAC } from "../../security/shared";
import { ReactComponent as DashboardICON } from "../../assets/Dashboard.svg";
import { ReactComponent as PostfachICON } from "../../assets/Postfach.svg";
import { ReactComponent as CaselistICON } from "../../assets/Caselist.svg";
import { ReactComponent as SettingsICON } from "../../assets/Settings.svg";
import { ReactComponent as UserICON } from "../../assets/User.svg";
import { ReactComponent as HelpICON } from "../../assets/Lifebouy.svg";
import { ReactComponent as LogoutICON } from "../../assets/Logout.svg";

export default function Sidebar() {
  const [sidePanelOpen, setsidePanelOpen] = useState(true);

  const { keycloak, initialized } = useKeycloak();
  const handleLogout = () => {
    keycloak.logout({
      redirectUri: window.location.origin,
    });
  };

  const isAnwalt = () =>
    initialized && keycloak.authenticated && keycloak.hasRealmRole(RBAC.anwalt); // Make sure 'anwalt' matches the role name exactly
  const isMandant = () =>
    initialized &&
    keycloak.authenticated &&
    keycloak.hasRealmRole(RBAC.mandant); // Make sure 'anwalt' matches the role name exactly

  return (
    <motion.div
      initial={{
        width: "8rem",
      }}
      animate={{
        width: sidePanelOpen ? "16rem" : "6rem",
      }}
      transition={{
        duration: 0.5,
        type: "ease",
      }}
      className="h-full bg-base-300 flex flex-col items-center justify-between py-8 rounded-default"
    >
      {/* ICONS ON TOP */}
      <div className={`flex flex-col justify-start ${sidePanelOpen && "w-52"}`}>
        <div
          className="flex items-center gap-2 cursor-pointer p-2 border-base-100 border-b pb-4 mb-6"
          onClick={() => setsidePanelOpen(!sidePanelOpen)}
        >
          <div>
            <img src="/logo193.png" alt="Logo" className="w-10 h-10" />
          </div>
          {sidePanelOpen && (
            <p className="text-base font-medium pl-2">Wicode Software</p>
          )}
        </div>
        {/* <Link className="flex items-center gap-2 pt-4 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" className="w-9 h-9"><path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" /></svg>
                    { sidePanelOpen && <p className="text-lg font-semibold">Suche</p> }
                </Link> */}
        {isAnwalt() && (
          <LinkElement
            linkTo="/portal/dashboard"
            text="Dashboard"
            sidePanelOpen={sidePanelOpen}
            icon={<DashboardICON />}
          />
        )}
        {isAnwalt() && (
          <LinkElement
            linkTo="/portal/anfragen"
            text="Anfragen"
            sidePanelOpen={sidePanelOpen}
            icon={<PostfachICON />}
          />
        )}
        <LinkElement
          linkTo={
            isAnwalt()
              ? "/portal/mandanten"
              : isMandant()
              ? "/portal/mandant/faelle"
              : ""
          }
          text="Mandanten"
          icon={<UserICON />}
          sidePanelOpen={sidePanelOpen}
        />
        {isAnwalt() && (
          <LinkElement
            disabled
            linkTo={"/portal/anwalt/faelle"}
            text="Fälle"
            icon={<CaselistICON className="text-gray-300 w-7 h-7" />}
            sidePanelOpen={sidePanelOpen}
          />
        )}
      </div>
      {/* ICONS ON BOTTOM */}
      <div className={`flex flex-col ${sidePanelOpen && "w-52"}`}>
        {isAnwalt() && (
          <LinkElement
            linkTo="/portal/hilfe"
            text="Hilfe"
            sidePanelOpen={sidePanelOpen}
            icon={<HelpICON />}
          />
        )}
        <LinkElement
          linkTo="/portal/einstellungen"
          text="Einstellungen"
          sidePanelOpen={sidePanelOpen}
          icon={<SettingsICON />}
        />
        <LinkElement
          text="Logout"
          sidePanelOpen={sidePanelOpen}
          icon={<LogoutICON />}
          onClick={handleLogout}
        />
        <SidebarProfil
          linkTo={isAnwalt() ? "/portal" : "/portal/profil"}
          name={keycloak?.idTokenParsed?.given_name || ""}
          email={keycloak?.idTokenParsed?.email || ""}
          sidePanelOpen={sidePanelOpen}
        />
      </div>
    </motion.div>
  );
}

type LinkElementType = {
  sidePanelOpen: boolean;
  icon: ReactNode;
  text: string;
  linkTo?: string;
  disabled?: boolean;
  onClick?: () => void;
};

function LinkElement(props: LinkElementType) {
  return (
    <Link
      aria-disabled={props.disabled}
      // style={{ pointerEvents: "none" }}
      className={`flex items-center gap-2 p-2 rounded-default 
      ${!props.sidePanelOpen && "justify-center"}
      ${
        props.disabled
          ? "cursor-not-allowed"
          : "cursor-pointer hover:bg-black/10 "
      }
      `}
      onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      to={props.linkTo ?? ""}
    >
      {props.icon}
      {props.sidePanelOpen && (
        <p className={`font-medium ${props.disabled && "text-gray-300"}`}>
          {props.text}
        </p>
      )}
    </Link>
  );
}

type SidebarProfilType = {
  name: string;
  email: string;
  linkTo: string;
  sidePanelOpen: boolean;
};

function SidebarProfil(props: SidebarProfilType) {
  return (
    <Link
      to={props.linkTo}
      className="flex items-center gap-2 cursor-pointer py-2 px-1 hover:bg-base-300/30  border-base-100 border-t pt-4 mt-4"
    >
      <div
        className={`h-10 w-10 rounded-full flex justify-center items-center bg-primary border-neutral border`}
      >
        <p className="text-xl font-bold">{props.name[0]}</p>
      </div>
      {props.sidePanelOpen && (
        <div className="pl-2">
          <p className="text-sm font-semibold underline">{props.name}</p>
          <p className="text-xs truncate">{props.email}</p>
        </div>
      )}
    </Link>
  );
}
