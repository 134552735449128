import {
    EventType,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Component } from 'react'

const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0

const msalInstance = new PublicClientApplication({
    auth: {
        clientId:
            process.env.REACT_APP_CLIENT_ID ??
            'e834e1ed-97ff-48c1-bb1b-313f4d0547e9',
        authority:
            process.env.REACT_APP_AUTHORITY ??
            'https://login.microsoftonline.com/common',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        allowRedirectInIframe: true,
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error('ERROR' + message)
                        return
                    default:
                        return
                }
            },
        },
    },
})
msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
    ) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents()

    msalInstance.addEventCallback((event: any) => {
        switch (event.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
                if (
                    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
                    !event.payload?.account
                ) {
                    // For ACQUIRE_TOKEN_SUCCESS, only proceed if event.payload.account exists.
                    break // Exit switch without executing the next lines if the account is not present.
                }
                // This code runs for LOGIN_SUCCESS or ACQUIRE_TOKEN_SUCCESS (with a valid account).
                const account = event.payload.account
                msalInstance.setActiveAccount(account)
                break

            case EventType.ACQUIRE_TOKEN_FAILURE:
            case EventType.SSO_SILENT_FAILURE:
                msalInstance
                    .logoutRedirect({
                        account: msalInstance.getActiveAccount(),
                        onRedirectNavigate: () => false, // No navigation after redirect.
                    })
                    .catch((e) => {
                        console.error(e)
                    })
                break

            // Optionally, add a default case if you need to handle other event types.
            default:
                // Handle any other event types not explicitly mentioned above.
                break
        }
    })
})

interface MicrosoftAuthWrapperProps {}

export class MicrosoftAuthWrapper extends Component<
    React.PropsWithChildren<MicrosoftAuthWrapperProps>
> {
    render() {
        return (
            <MsalProvider instance={msalInstance}>
                {this.props.children}
            </MsalProvider>
        )
    }
}

export const msTopenScopes = ['User.Read', 'Calendars.ReadWrite']
