import { useContext, useEffect } from "react";
import BaseToggle from "../../../../../components/base/BaseToggle";
import BaseViolationForm from "../../../../../components/base/BaseViolationForm";
import { AddUserContext } from "../../../../../components/context/AddUserContext/AddUserContext";
import BaseDocsSelection from "../../../../../components/base/BaseDocsSelection";
import { ViolationScreenType } from "../../../Types";
import BaseInputField from "../../../../../components/base/BaseInputField";
import BaseTextAreaSpecialties from "../../../../../components/base/BaseTextAreaSpecialties";
import { checkForValue } from "../../../../../services/functions";

export default function PaceViolation(props: ViolationScreenType) {
  const addCaseContext = useContext(AddUserContext);
  useEffect(() => {
    addCaseContext?.clearData();
    addCaseContext?.changeData(2, "title", "Geschwindigkeitsverstoß");
  }, []);

  // Logic to disable Weiter-Button
  useEffect(() => {
    if (
      checkForValue(addCaseContext?.caseData?.toggle1) &&
      checkForValue(addCaseContext?.caseData?.toggle2) &&
      checkForValue(addCaseContext?.caseData?.toggle3) &&
      checkForValue(addCaseContext?.caseData?.toggle4) &&
      checkForValue(addCaseContext?.caseData?.dd1)
    ) {
      props.setfilledOut();
    }
  }, [addCaseContext?.caseData, props]);

  return (
    <BaseViolationForm>
      <BaseToggle
        group="location"
        title={"Ort der Geschwindigkeitsübertretung *"}
        label1="Innerorts"
        label2="Außerorts"
        value1="Wo: innerorts"
        value2="Wo: außerorts"
        checked={addCaseContext?.caseData?.toggle1}
        toggle={"toggle1"}
      />
      <BaseToggle
        group="vehicle-type"
        title={"Fahrzeugtyp *"}
        label1="PKW/Motorrad"
        label2="LKW"
        value1="Fahrzeugtyp: PKW/Motorrad"
        value2="Fahrzeugtyp: LKW"
        checked={addCaseContext?.caseData?.toggle2}
        toggle={"toggle2"}
      />
      <p className="pt-8">Geschwindkeitsüberschreitung *</p>
      <select
        value={addCaseContext?.caseData?.dd1}
        onChange={(e) => addCaseContext?.changeData(2, "dd1", e.target.value)}
        className="select select-bordered w-full bg-base-200 select-primary border-2"
      >
        <option disabled selected>
          Bitte auswählen...
        </option>
        <option value={"Geschwindkeitsüberschreitung: 0 - 15 km/h"}>
          0 - 15 km/h
        </option>
        <option value={"Geschwindkeitsüberschreitung: 16 - 20 km/h"}>
          16 - 20 km/h
        </option>
        <option value={"Geschwindkeitsüberschreitung: 21 - 30 km/h"}>
          21 - 30 km/h
        </option>
        <option value={"Geschwindkeitsüberschreitung: 31 - 40 km/h"}>
          31 - 40 km/h
        </option>
        <option value={"Geschwindkeitsüberschreitung: mehr als 40 km/h"}>
          mehr als 40 km/h
        </option>
      </select>

      <BaseToggle
        group="timeline"
        title={
          "Wurden Sie in den letzten 12 Monaten bereits mit mehr als 12 km/h geblitzt? *"
        }
        label1="Ja"
        label2="Nein"
        value1="in den letzten 12 Monaten mit über 12km/h zu viel geblitzt?: Ja"
        value2="in den letzten 12 Monaten mit über 12km/h zu viel geblitzt?: Nein"
        checked={addCaseContext?.caseData?.toggle3}
        toggle={"toggle3"}
      />
      <BaseToggle
        group="necessary"
        title={"Sind Sie beruflich auf den Führerschein angewiesen? *"}
        label1="Ja"
        label2="Nein"
        value1="Beruflich Angewiesen?: Ja"
        value2="Beruflich Angewiesen?: Nein"
        checked={addCaseContext?.caseData?.toggle4}
        toggle={"toggle4"}
      />
      <BaseTextAreaSpecialties
        title={"Bemerkung "}
        heightProps="max-h-32 h-full"
        value={addCaseContext?.caseData?.note}
      />
      <BaseInputField
        label={"Frist des Falls"}
        type={"date"}
        placeholder={""}
        value={addCaseContext?.caseData?.deadline}
        attribut="deadline"
        stage={2}
        bg="bg-base-200"
        width="w-full"
        bigText
      />
      <BaseDocsSelection extend={false} />
    </BaseViolationForm>
  );
}
