export default function MobileBlocker() {
  return (
    <div className="lg:hidden flex flex-col px-8 justify-center items-center w-full h-full">
      <p className="text-2xl underline font-bold uppercase">Entschuldigung!</p>
      <p className="text-lg tracking-wide font-medium text-center leading-5 pt-2 max-w-sm">
        Die Plattform ist aus Usability-Gründen auf Ihrer Bildschirmgröße nicht
        erreichbar.
      </p>
    </div>
  );
}
