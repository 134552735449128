import { useParams } from "react-router-dom";
import BasePanelLayout from "../../components/base/BasePanelLayout";
import RequestList from "../../components/admin/RequestList";
import RequestProfile from "../../components/admin/RequestProfile";
import { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getNewCases } from "../../api/apiCalls";
import Loading from "../../components/common/Loading";
import ErrorPage from "../common/ErrorPage";
import { newCaseType } from "../../types/Types";
import BaseActionCheckModal from "../../components/base/BaseActionCheckModal";
import {
  useDeleteCaseMutation,
  useDeleteUserMutation,
} from "../../api/services";
import { ModalContext } from "../../components/context/ModalContext/ModalContext";

export default function AdminScreen2() {
  const { error, data, isLoading } = useQuery({
    queryKey: ["newCasesData"],
    queryFn: getNewCases,
  });

  const { id, caseid } = useParams();

  const [selectedrequest, setselectedrequest] = useState<
    newCaseType | undefined
  >();
  useEffect(() => {
    if (data && data.length > 0) {
      setselectedrequest(
        data.find(
          (obj: { userId: string; _id: string }) =>
            obj.userId === id && obj._id === caseid
        ) ?? data[0]
      );
    } else if (data && data.length === 0) {
      setselectedrequest(undefined);
    }
  }, [data, id, caseid]);

  // delete Case or User because Request is denied
  const queryClient = useQueryClient();
  const { mutate: deleteReqCase } = useDeleteCaseMutation(queryClient);
  const { mutate: deleteReqUser } = useDeleteUserMutation(queryClient);
  const handleDelete = () => {
    setselectedrequest(undefined);
    if (selectedrequest) {
      if (selectedrequest?.casesCount > 1) {
        deleteReqCase({
          uid: selectedrequest?.userId ?? "",
          cid: selectedrequest?._id ?? "",
        });
        modalCon?.trigger(0);
      } else if (selectedrequest?.casesCount === 1) {
        deleteReqUser({ uid: selectedrequest?.userId });
        modalCon?.trigger(0);
      }
    }
  };
  const modalCon = useContext(ModalContext);

  return (
    <>
      {isLoading && <Loading />}
      {error && <ErrorPage />}
      {!error && !isLoading && (
        <BasePanelLayout>
          <RequestList
            scrollable
            classProps="w-2/5"
            fullScreen={false}
            currentCaseId={caseid ?? data[0]?._id ?? ""}
            requests={data}
          />
          <RequestProfile
            request={selectedrequest}
            deleteFunc={() => modalCon?.trigger(0)}
          />
          <BaseActionCheckModal
            modalId={0}
            title={"Fall Ablehnen"}
            buttonTitle={"Ablehnen"}
            text={
              "Sind Sie sicher, dass Sie den Fall ablehnen wollen? Der User wird unwiederbringlich aus der Datenbank gelöscht, falls er noch nicht angenommen wurde."
            }
            function={handleDelete}
          />
        </BasePanelLayout>
      )}
    </>
  );
}
