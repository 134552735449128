import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const laws = [
  "Verkehrsrecht",
  "Arbeitsrecht",
  "Beamtenrecht",
  "Markenrecht",
  "Mietrecht",
  "Verwaltungsrecht",
  "Erbrecht",
  "Datenschutzrecht",
  "Anderes",
];

export default function RegistrationChooseLaw() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-start gap-4 pt-16">
      <img src="/logo193.png" alt="Logo" className="h-32 w-32" />
      <p className="text-2xl py-4">
        In welcher Rechtssache wollen Sie einen Fall melden?
      </p>
      <div className="flex flex-wrap w-full justify-center gap-8 max-w-screen-2xl px-8 overflow-y-scroll pl-12 pb-8">
        {laws.map((item, index) => (
          <LawType
            title={item}
            key={index}
            disabled={item !== "Verkehrsrecht" && item !== "Arbeitsrecht"}
          />
        ))}
      </div>
    </div>
  );
}

type LawTypeType = {
  title: string;
  disabled?: boolean;
};

function LawType(props: LawTypeType) {
  return (
    <Link
      to={"/registrierung/fall-melden/" + props.title.toLowerCase()}
      onClick={props.disabled ? (e) => e.preventDefault() : () => {}}
    >
      <motion.div
        whileHover={{
          scale: !props.disabled ? 1.1 : 1,
          transition: {
            duration: !props.disabled && 0.2,
          },
        }}
        whileTap={{
          scale: 0.95,
        }}
        className={`rounded-default shadow-lg w-80 h-40 flex justify-center items-center ${
          props.disabled
            ? "bg-base-300/80 cursor-default"
            : "bg-base-300/30 cursor-pointer "
        }`}
      >
        <p
          className={`uppercase font-semibold tracking-wide text-lg ${
            props.disabled && "text-gray-400"
          }`}
        >
          {props.title}
        </p>
      </motion.div>
    </Link>
  );
}
